import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const maintenanceUrl = "/client/api/v1/maintenance";

const getMaintenance = async (id) => {
  const url = `${baseUrl}${maintenanceUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addMaintenance = async (body) => {
  const url = `${baseUrl}${maintenanceUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of maintenances
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of maintenances
 */
const findAllMaintenance = async (body) => {
  const options = {
    page: 1,
    paginate: 1000,
  };
  const url = `${baseUrl}${maintenanceUrl}/list`;
  const response = await fetchWrapper().post(url, { options, ...body });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const getMaintenancesWithClients = async (body) => {
  const url = `${baseUrl}${maintenanceUrl}/listWithClients`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeleteMaintenance = async (id) => {
  const url = `${baseUrl}${maintenanceUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdateMaintenance = async (id, body) => {
  const url = `${baseUrl}${maintenanceUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updateMaintenance = async (id, body) => {
  const url = `${baseUrl}${maintenanceUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const getCountMaintenances = async (body) => {
  const options = {
    page: 1,
    paginate: 1000,
  };
  const url = `${baseUrl}${maintenanceUrl}/count`;
  const response = await fetchWrapper().post(url, { options, ...body });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const maintenanceService = {
  getMaintenance,
  addMaintenance,
  findAllMaintenance,
  getMaintenancesWithClients,
  softDeleteMaintenance,
  partialUpdateMaintenance,
  updateMaintenance,
  getCountMaintenances,
};

export default maintenanceService;
