import { StyleSheet } from "aphrodite";

const tablePage = StyleSheet.create({
  tablePagePoolView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em 2rem",
    borderRadius: "10px",
    background: "var(--helio-light-color)",
  },
  
  tablePage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em 2rem",
    borderRadius: "10px",
    background: "var(--helio-light-color)",
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.25)",
  },

  header: {
    width: "100%",
    marginBottom: "2rem",
  },

  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },

  table: {
    borderCollapse: "collapse",
    marginBottom: "1rem",
  },

  iconTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  cell: {
    textAlign: "center",
    padding: "8px",
    borderBottom: "1px solid var(--helio-dark-color)",
  },

  noWrapCell: {
    whiteSpace: "nowrap",
  },

  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: "var(--helio-green-color)",
    ":hover": {
      textDecoration: "underline",
    },
  },
});

export default tablePage;
