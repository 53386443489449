import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { css } from "aphrodite";
import { card } from "styles";
import authConstants from "constants/authConstants";

function UserCard({ user }) {
  const navigate = useNavigate();

  return (
    <div className={css(card.panel)}>
      <div className={css(card.info)}>
        {(user.firstName || user.lastName) && (
          <p className={css(card.name)}>
            <b>
              {user.firstName} {user.lastName}
            </b>
          </p>
        )}
        <p className={css(card.name)}>
          <b>Identifiant : </b>
          {user.username}
        </p>
        <p className={css(card.name)}>
          <b>Rôle : </b>
          {Object.keys(authConstants.USER_TYPES).find(
            (key) => authConstants.USER_TYPES[key] === user.userType
          )}
        </p>
      </div>
      <div>
        <Button
          onClick={() => navigate(`/user/${user.id}`, { state: { user } })}
          color="dark"
        >
          Voir
        </Button>
      </div>
    </div>
  );
}

export default UserCard;
