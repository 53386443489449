import authConstants from "constants/authConstants";
import authService from "services/authService";
import AdminDashboard from "./AdminDashboard";
import TechDashboard from "./TechDashboard";
import ClientDashboard from "./ClientDashboard";

function Dashboard() {
  return (
    <>
      {authService.getConnectedUser().userType ===
        authConstants.USER_TYPES.Admin && <AdminDashboard />}
      {authService.getConnectedUser().userType ===
        authConstants.USER_TYPES.Tech && <TechDashboard />}
      {authService.getConnectedUser().userType ===
        authConstants.USER_TYPES.Client && <ClientDashboard />}
    </>
  );
}

export default Dashboard;
