import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextInput, Button, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import userService from "services/userService";
import roleService from "services/roleService";
import userRoleService from "services/userRoleService";
import { css } from "aphrodite";
import { view, global } from "styles";
import authConstants from "constants/authConstants";
import notif from "components/common/notification";

function UserUpdate() {
  // eslint-disable-next-line
  const [user, setUser] = useState(null);
  // eslint-disable-next-line
  const [userRoles, setUserRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      // email: "",
      mobileNo: "",
      password: "",
      userType: 0,
    },
    validate: {
      username: (value) => (value === "" ? "champ requis" : null),
      // email: (value) => (value === "" ? "champ requis" : null),
      userType: (value) => (value === 0 ? "champ requis" : null),
    },
  });

  useEffect(() => {
    loadAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAll = async () => {
    await getAllRoles();
    if (id) {
      await getUser(parseInt(id));
    }
  };

  const getUser = async (id) => {
    try {
      // get user
      let result = await userService.getUser(id);
      setUser(result.data);
      updateForm(result.data);

      // get user roles
      result = await userRoleService.findAllUserRole({ query: { userId: id } });
      setUserRoles(result.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllRoles = async () => {
    try {
      // get all roles
      const result = await roleService.findAllRole();
      setAllRoles(result.data);
    } catch (err) {
      console.error(err);
    }
  };

  const updateUser = async (id, body) => {
    try {
      // update user
      delete body.password;
      body.userType = parseInt(body.userType);
      await userService.partialUpdateUser(id, body);
      // delete all current user roles
      let query = {
        ids: userRoles.map((r) => r.id),
      };
      await userRoleService.deleteManyUserRole(query);

      // create new user roles
      const role = allRoles.find(
        (r) =>
          r.name ===
          Object.keys(authConstants.USER_TYPES).find(
            (key) => authConstants.USER_TYPES[key] === body.userType
          )
      );
      await userRoleService.addUserRole({
        userId: id,
        roleId: role.id,
      });
      navigate(`/user/${id}`);
    } catch (err) {
      console.error(err);
      notif.error(getErrorMessage(err));
    }
  };

  async function createUser(body) {
    try {
      // create user
      body.userType = parseInt(body.userType);
      const newUser = await userService.addUser(body);

      // create new user roles
      const role = allRoles.find(
        (r) =>
          r.name ===
          Object.keys(authConstants.USER_TYPES).find(
            (key) => authConstants.USER_TYPES[key] === body.userType
          )
      );
      await userRoleService.addUserRole({
        userId: newUser.data.id,
        roleId: role.id,
      });
      navigate(-1);
    } catch (err) {
      console.error(err);
      notif.error(getErrorMessage(err));
    }
  }

  const getErrorMessage = (err) => {
    if (err.message.includes("Only unique username are allowed")) {
      return "Cet identifiant est déjà utilisé";
    }
    // if (err.message.includes("Only unique email are allowed")) {
    //   return "cet email est déjà utilisé";
    // }
    return "Impossible de créer ou modifier cet utilisateur";
  };

  function updateForm(user) {
    form.setFieldValue("firstName", user.firstName || "");
    form.setFieldValue("lastName", user.lastName || "");
    form.setFieldValue("username", user.username || "");
    // form.setFieldValue("email", user.email || "");
    form.setFieldValue("mobileNo", user.mobileNo || "");
    form.setFieldValue("userType", String(user.userType));
  }

  function handleSubmit() {
    if (id) {
      updateUser(parseInt(id), form.values);
    } else {
      createUser(form.values);
    }
  }

  return (
    <>
      <BackButton />
      <div className={css(view.view)}>
        <h2 className={css(global.title)}>
          {id ? "MODIFICATION UTILISATEUR" : "NOUVEL UTILISATEUR"}
        </h2>
        <form
          className={css(global.form)}
          onSubmit={form.onSubmit(() => handleSubmit())}
        >
          <TextInput
            label="Prénom"
            placeholder="Prénom"
            {...form.getInputProps("firstName")}
          />
          <TextInput
            label="Nom"
            placeholder="Nom"
            {...form.getInputProps("lastName")}
          />
          <TextInput
            label="Identifiant"
            placeholder="Identifiant"
            {...form.getInputProps("username")}
          />
          {/* <TextInput
            label="Email"
            placeholder="Adresse@email.com"
            {...form.getInputProps("email")}
          /> */}
          <TextInput
            label="Téléphone"
            placeholder="06 45 78 84 95"
            {...form.getInputProps("mobileNo")}
          />
          <Select
            data={Object.keys(authConstants.USER_TYPES).map((k) => ({
              label: k,
              value: authConstants.USER_TYPES[k].toString(),
            }))}
            label="Rôle"
            placeholder="Sélectionnez le rôle"
            {...form.getInputProps("userType")}
          />
          {!id && (
            <TextInput
              label="Mot de passe provisoire"
              placeholder="Mot de passe"
              {...form.getInputProps("password")}
            />
          )}

          <Button type="submit">Enregistrer</Button>
          <Button onClick={() => navigate(-1)} color="dark">
            Annuler
          </Button>
        </form>
      </div>
    </>
  );
}

export default UserUpdate;
