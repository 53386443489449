import { StyleSheet } from "aphrodite";

const global = StyleSheet.create({
  title: {
    width: "100%",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    rowGap: "2rem",
    width: "100%",
    maxWidth: "500px",
  },
});

export default global;
