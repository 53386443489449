import { Button, Grid, FileInput, Loader } from "@mantine/core";
import ProtectedRoute from "components/common/ProtectedRoute";
import { useEffect, useState } from "react";
import maintenancePhotoService from "services/maintenancePhotoService";
import uploadService from "services/uploadService";
import MaintenancePhotoView from "./MaintenancePhotoView";
import imageCompression from "browser-image-compression";

function MaintenancePhotoList({ maintenanceId, onCountChanged, photoType }) {
  const [isUploading, setIsUploading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    maintenancePhotoService
      .findAllMaintenancePhoto({
        query: {
          maintenance: maintenanceId,
          type: photoType,
          isDeleted: false,
        },
      })
      .then((res) => {
        res.data.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setPhotos(res.data);
        onCountChanged(res.data.length);
      })
      .catch((err) => console.error(err));
  }, [maintenanceId, onCountChanged]);

  const onDeletePhoto = (id) => {
    onCountChanged(photos.length - 1);
    setPhotos((current) => current.filter((x) => x.id !== id));
  };

  const addPhotos = async (event) => {
    event.preventDefault();
    setIsUploading(true);

    try {
      // compress files
      const options = { maxSizeMB: 1, maxWidthOrHeight: 1920 };
      const compressedFiles = await Promise.all(
        Array.from(files).map((file) => imageCompression(file, options))
      );

      // add compressed files to FormData object
      const formData = new FormData();
      compressedFiles.forEach((file) => {
        formData.append(`files`, file, file.name);
      });

      // upload files
      let response = await uploadService.upload(formData);

      // create maintenancePhoto entries
      const promises = response.data.uploadSuccess.map((x) =>
        maintenancePhotoService.addMaintenancePhoto({
          maintenance: maintenanceId,
          type: photoType,
          url: x.path,
        })
      );
      response = await Promise.all(promises);
      const newPhotos = response.map((x) => x.data);
      onCountChanged(photos.length + 1);
      setPhotos((current) => [...newPhotos, ...current]);
      setFiles([]);
    } catch (err) {
      console.error(err);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      <ProtectedRoute routeRoles={["Admin", "Tech"]} blockingComponent={<></>}>
        <form onSubmit={addPhotos}>
          <Grid columns={4}>
            <Grid.Col span={3}>
              <ProtectedRoute
                requiresOnline
                blockingComponent={
                  <FileInput
                    disabled
                    placeholder="Vous devez être en ligne..."
                  />
                }
              >
                <FileInput
                  multiple
                  disabled={isUploading}
                  placeholder="Sélectionner des photos"
                  accept="image/png,image/jpeg"
                  name="files"
                  value={files}
                  onChange={setFiles}
                />
                <Button fullWidth type="submit" disabled={!files.length}>
                  Ajouter
                </Button>
              </ProtectedRoute>
            </Grid.Col>
            <Grid.Col span={1}>
              {isUploading ? (
                <Loader />
              ) : (
                <ProtectedRoute
                  requiresOnline
                  blockingComponent={
                    <Button fullWidth type="submit" disabled>
                      Ajouter
                    </Button>
                  }
                >
                  <Button fullWidth type="submit" disabled={!files.length}>
                    Ajouter
                  </Button>
                </ProtectedRoute>
              )}
            </Grid.Col>
          </Grid>
        </form>
      </ProtectedRoute>
      <br />

      <Grid justify="center">
        {photos.map((photo) => (
          <Grid.Col key={photo.id} span={6} style={{ minWidth: 250 }}>
            <MaintenancePhotoView
              photo={photo}
              onPhotoDeleted={onDeletePhoto}
            />
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
}

export default MaintenancePhotoList;
