import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ThemeIcon,
  Accordion,
  Anchor,
  Button,
  Modal,
  Space,
  Tabs,
  List,
  Skeleton,
  Loader,
  Group,
  Tooltip,
} from "@mantine/core";
import { css } from "aphrodite";
import { view, global } from "styles";
import maintenanceService from "services/maintenanceService";
import poolService from "services/poolService";
import { CircleCheck, CircleX, InfoCircle } from "tabler-icons-react";
import clientService from "services/clientService";
import InfoLine from "components/common/InfoLine";
import userService from "services/userService";
import SuppliedProductList from "components/product/SuppliedProductList";
import maintenanceCommentService from "services/maintenanceCommentService";
import MaintenanceCommentView from "../maintenanceComment/MaintenanceCommentView";
import MaintenancePhotoList from "components/maintenancePhoto/MaintenancePhotoList";
import maintenancePhotoService from "services/maintenancePhotoService";
import authConstants from "constants/authConstants";
import authService from "services/authService";
import productService from "services/productService";
import ProtectedRoute from "components/common/ProtectedRoute";

function MaintenanceView() {
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maintenance, setMaintenance] = useState(null);
  const [pool, setPool] = useState(null);
  const [client, setClient] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [photoCountBefore, setPhotoCountBefore] = useState(0);
  const [photoCountAfter, setPhotoCountAfter] = useState(0);
  const [suppliedProducts, setSuppliedProducts] = useState([]);

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getInfo(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { }, []);

  const getInfo = async (id) => {
    setIsLoading(true);
    try {
      // get maintenance
      let result = await maintenanceService.getMaintenance(id);
      const maintenance = result.data;
      if (!maintenance || maintenance.isDeleted) {
        navigate("/maintenancenotfound");
      }
      setMaintenance(maintenance);
      setSuppliedProducts(JSON.parse(maintenance.suppliedProducts || []));

      // get pool
      result = await poolService.getPool(result.data.pool);
      setPool(result.data);

      // get client
      result = await clientService.getClient(result.data.client);
      setClient(result.data);

      // get all users
      result = await userService.findAllUser({
        query: { isDeleted: false },
      });

      // set maintenance technicians
      const technicians = result.data.filter(
        (x) => x.userType === authConstants.USER_TYPES.Tech
      );
      if (authService.getUserType() === authConstants.USER_TYPES.Tech) {
        technicians.push(authService.getConnectedUser());
      }
      setTechnicians(
        technicians.filter((x) => maintenance.technicians.includes(x.id))
      );

      // get all products
      productService
        .findAllProduct({ query: { isDeleted: false } })
        .catch(() => { });

      // get comment count
      maintenanceCommentService
        .getCountMaintenanceComment({
          where: { maintenance: maintenance.id },
        })
        .then((res) => {
          if (res) setCommentCount(res.count);
        });

      // get photo count before
      maintenancePhotoService
        .getCountMaintenancePhoto({
          where: {
            maintenance: maintenance.id,
            type: "BEFORE",
            isDeleted: false,
          },
        })
        .then((res) => {
          if (res) setPhotoCountBefore(res.count);
        });

      // get photo count after
      maintenancePhotoService
        .getCountMaintenancePhoto({
          where: {
            maintenance: maintenance.id,
            type: "AFTER",
            isDeleted: false,
          },
        })
        .then((res) => {
          if (res) setPhotoCountAfter(res.count);
        });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteMaintenance = async (id) => {
    try {
      await maintenanceService.softDeleteMaintenance(id);
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  const getFormattedDateTime = (date) => {
    date = new Date(date);
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2)
    );
  };

  const getMandatoryActionsIcon = () => {
    const fields = [
      maintenance.hydraulicCircuit,
      maintenance.waterLevelAdjust,
      maintenance.clockSettings,
      maintenance.wallCleaning,
      maintenance.skimmerCleaning,
      maintenance.differentialTest,
      maintenance.waterLineCleaning,
      maintenance.filterCleaning,
    ];
    const done = fields.reduce((prev, curr) => prev + (curr ? 1 : 0), 0);

    return (
      <Tooltip
        label={`${done} sur ${fields.length} réalisées`}
        events={{ touch: true, focus: true, hover: true }}
      >
        <div>
          {done === fields.length ? (
            <CircleCheck color="green" />
          ) : (
            <CircleX color="red" />
          )}
        </div>
      </Tooltip>
    );
  };

  const getOptionalActionsIcon = () => {
    const fields = [
      maintenance.chocTreatment,
      maintenance.saltLevelAdjust,
      maintenance.sondeWintering,
      maintenance.shutterCleaning,
      maintenance.cover,
      maintenance.pacPurge,
    ];
    const done = fields.reduce((prev, curr) => prev + (curr ? 1 : 0), 0);

    return (
      <Tooltip
        label={`${done} sur ${fields.length} réalisées`}
        events={{ touch: true, focus: true, hover: true }}
      >
        <div>
          {done === fields.length ? (
            <CircleCheck color="green" />
          ) : (
            <InfoCircle color="orange" />
          )}
        </div>
      </Tooltip>
    );
  };

  function MaintenanceInfo({ handleModify, handleDelete }) {
    return (
      <div className={css(view.info)}>
        <ProtectedRoute
          routeRoles={["Admin", "Tech"]}
          blockingComponent={<></>}
        >
          <Button fullWidth onClick={handleModify} color="dark">
            Modifier
          </Button>
        </ProtectedRoute>
        <InfoLine label="Type de visite :" value={`${maintenance.type}`} />
        <InfoLine
          label="Date :"
          value={`${getFormattedDateTime(maintenance.date)}`}
        />
        <InfoLine label="Technicien(s) :">
          <List>
            {technicians.map((tech) => (
              <Anchor
                key={tech.id}
                variant="text"
                className={css(view.value)}
                component={Link}
                to={`/user/${tech.id}`}
              >
                {tech.username}
              </Anchor>
            ))}
          </List>
        </InfoLine>
        <InfoLine
          label="Aspect général du bassin - observations :"
          value={maintenance.generalAspect}
        />
        <InfoLine
          label="Turbidité de l'eau :"
          value={`${maintenance.waterTurbidity}`}
        />
        <InfoLine label="Couleur :" value={`${maintenance.waterColor}`} />
        <InfoLine
          label="Utilisation de produit(s) d'entretien et/ou traitement inapproprié(s) :"
          value={maintenance.badProductUsage}
        />
        <InfoLine label="Anomalies constatées :" value={maintenance.anomalies} />
        <Accordion>
          <Accordion.Item value="results">
            <Accordion.Control>RÉSULTATS D'ANALYSE</Accordion.Control>
            <Accordion.Panel>
              <InfoLine label="Chlore libre :" value={maintenance.freeChlore} />
              <InfoLine label="Chlore total :" value={maintenance.totalChlore} />
              <InfoLine
                label="Chlore combiné :"
                value={maintenance.combinedChlore}
              />
              <InfoLine label="PH :" value={maintenance.pH} />
              <InfoLine label="Température :" value={maintenance.temperature} />
              <InfoLine label="Alk :" value={maintenance.alk} />
              <InfoLine label="Cya :" value={maintenance.cya} />
              <InfoLine label="Phosph :" value={maintenance.phosph} />
              <InfoLine label="Brome :" value={maintenance.brome} />
              <InfoLine label="Sel :" value={maintenance.salt} />
              <InfoLine
                label="Autres si pertinent :"
                value={maintenance.otherResults}
              />
              <InfoLine
                label="Actions curatives suite à analyse ci-dessus :"
                value={maintenance.curativeAction}
              />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="mandatory">
            <Accordion.Control>
              <Group noWrap>
                {getMandatoryActionsIcon()}
                <p>ACTIONS À EFFECTUER À CHAQUE VISITE</p>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <InfoLine label="Vérification circuit hydraulique :">
                <BoolValid valid={maintenance.hydraulicCircuit} />
              </InfoLine>
              <InfoLine label="Ajustement niveau d'eau :">
                <BoolValid valid={maintenance.waterLevelAdjust} />
              </InfoLine>
              <InfoLine label="Réglage de l'horloge :">
                <BoolValid valid={maintenance.clockSettings} />
              </InfoLine>
              <InfoLine label="Nettoyage fond/parois :">
                <BoolValid valid={maintenance.wallCleaning} />
              </InfoLine>
              <InfoLine label="Nettoyage skimmers :">
                <BoolValid valid={maintenance.skimmerCleaning} />
              </InfoLine>
              <InfoLine label="Test différentiel :">
                <BoolValid valid={maintenance.differentialTest} />
              </InfoLine>
              <InfoLine label="Nettoyage ligne d'eau :">
                <BoolValid valid={maintenance.waterLineCleaning} />
              </InfoLine>
              <InfoLine label="Lavage de filtre et préfiltre :">
                <BoolValid valid={maintenance.filterCleaning} />
              </InfoLine>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="optional">
            <Accordion.Control>
              <Group noWrap>
                {getOptionalActionsIcon()}
                <p>
                  ACTIONS SUPPLÉMENTAIRES À EFFECTUER EN ESTIVAGE ET/OU
                  HIVERNAGE
                </p>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <InfoLine label="Traitement choc :">
                <BoolValid optional={true} valid={maintenance.chocTreatment} />
              </InfoLine>
              <InfoLine label="Ajustement niveau de sel :">
                <BoolValid
                  optional={true}
                  valid={maintenance.saltLevelAdjust}
                />
              </InfoLine>
              <InfoLine label="Hivernage sondes remises client :">
                <BoolValid optional={true} valid={maintenance.sondeWintering} />
              </InfoLine>
              <InfoLine label="Nettoyage volet (optionnel) :">
                <BoolValid
                  optional={true}
                  valid={maintenance.shutterCleaning}
                />
              </InfoLine>
              <InfoLine label="Couverture été/hiver :">
                <BoolValid optional={true} valid={maintenance.cover} />
              </InfoLine>
              <InfoLine label="Mise en route / purge PAC :">
                <BoolValid optional={true} valid={maintenance.pacPurge} />
              </InfoLine>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <SuppliedProductList values={suppliedProducts} />

        <InfoLine
          label="Préconisations et conseils donnés au client :"
          value={maintenance.advice}
        />

        <ProtectedRoute
          routeRoles={["Admin", "Tech"]}
          blockingComponent={<></>}
        >
          <Button fullWidth onClick={handleModify} color="dark">
            Modifier
          </Button>
          <Button
            disabled={!navigator.onLine}
            fullWidth
            color="alert"
            onClick={handleDelete}
          >
            Supprimer
          </Button>
        </ProtectedRoute>
      </div>
    );
  }

  return (
    <>
      <BackButton />
      <div className={css(view.view)}>
        <h2 className={css(global.title)}>FICHE VISITE D'ENTRETIEN</h2>

        {/* pool info */}
        <div className={css(view.info)}>
          <InfoLine label="Piscine associée :">
            {isLoading && <Skeleton radius="xl" />}
            {pool ? (
              <Anchor
                variant="text"
                className={css(view.value)}
                component={Link}
                to={`/pool/${pool.id}`}
              >
                {pool.address}
              </Anchor>
            ) : (
              <p>...</p>
            )}
          </InfoLine>
        </div>

        {/* client info */}
        <div className={css(view.info)}>
          <InfoLine label="Client associé :">
            {isLoading && <Skeleton radius="xl" />}
            {client ? (
              <Anchor
                variant="text"
                className={css(view.value)}
                component={Link}
                to={`/client/${client.id}`}
              >
                {client.firstName} {client.lastName}
              </Anchor>
            ) : (
              <p>...</p>
            )}
          </InfoLine>
        </div>
        <Space h="md" />

        <Tabs
          className={css(view.info)}
          variant="pills"
          radius={"sm"}
          defaultValue="informations"
        >
          <Tabs.List position="left">
            <Tabs.Tab value="informations">
              <b>Informations</b>
            </Tabs.Tab>
            <Tabs.Tab value="comments">
              <b>Commentaires ({commentCount})</b>
            </Tabs.Tab>
            <Tabs.Tab value="photosBefore">
              <b>Photos avant nettoyage ({photoCountBefore})</b>
            </Tabs.Tab>
            <Tabs.Tab value="photosAfter">
              <b>Photos après nettoyage ({photoCountAfter})</b>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="informations">
            {/* maintenance info */}
            {isLoading && <Loader />}
            {maintenance && (
              <MaintenanceInfo
                handleModify={() => navigate("update")}
                handleDelete={() => setOpened(true)}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel value="comments">
            <MaintenanceCommentView onCommentCountChange={setCommentCount} />
          </Tabs.Panel>

          <Tabs.Panel value="photosBefore">
            <MaintenancePhotoList
              maintenanceId={parseInt(id)}
              photoType={"BEFORE"}
              onCountChanged={setPhotoCountBefore}
            />
          </Tabs.Panel>

          <Tabs.Panel value="photosAfter">
            <MaintenancePhotoList
              maintenanceId={parseInt(id)}
              photoType={"AFTER"}
              onCountChanged={setPhotoCountAfter}
            />
          </Tabs.Panel>
        </Tabs>
      </div>

      <DeleteModal
        opened={opened}
        setOpened={setOpened}
        handleClick={() => deleteMaintenance(id)}
      />
    </>
  );
}

function DeleteModal({ opened, setOpened, handleClick }) {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Voulez-vous vraiment supprimer cette visite d'entretien ?"
    >
      <div className={css(view.modalButtons)}>
        <Button onClick={handleClick} color="alert">
          Supprimer
        </Button>
        <Button onClick={() => setOpened(false)} color="dark">
          Annuler
        </Button>
      </div>
    </Modal>
  );
}

function BoolValid({ valid, optional = false }) {
  return (
    <ThemeIcon variant="outline" color="transparent">
      {valid ? (
        <CircleCheck color="green" />
      ) : (
        <CircleX color={optional ? "orange" : "red"} />
      )}
    </ThemeIcon>
  );
}

export default MaintenanceView;
