import { ActionIcon, Group, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import BackButton from "components/common/BackButton";
import notif from "components/common/notification";
import { CirclePlus, Trash } from "tabler-icons-react";
const { useEffect, useState } = require("react");
const { default: productService } = require("services/productService");

function ProductListUpdate() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    productService
      .findAllProduct({ query: { isDeleted: false } })
      .then((res) => {
        setProducts(res.data.sort((a, b) => a.name.localeCompare(b.name)));
      });
  }, []);

  const form = useForm({
    initialValues: {
      name: "",
    },
    validate: {
      name: (value) => (value === "" ? "champ requis" : null),
    },
  });

  const handleSubmit = () => {
    createProduct(form.values);
  };

  const createProduct = (product) => {
    productService
      .addProduct(product)
      .then((res) => {
        form.reset();
        setProducts((current) => [...current, res.data].sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        notif.error("Impossible de créer le produit pour le moment");
      });
  };

  const deleteProduct = (id) => {
    productService
      .softDeleteProduct(id)
      .then((res) => {
        setProducts((current) =>
          current.filter((p) => p.id !== res.data[0].id)
        );
      })
      .catch((err) => {
        notif.error("Impossible de supprimer le produit pour le moment");
      });
  };

  const ProductElement = ({ product }) => {
    return (
      <Group grow>
        <Text>{product.name}</Text>
        <ActionIcon size={20} onClick={() => deleteProduct(product.id)}>
          <Trash color="red" size={25}></Trash>
        </ActionIcon>
      </Group>
    );
  };

  return (
    <>
      <BackButton />
      <div
        style={{
          margin: "auto",
          maxWidth: "400px",
          borderRadius: "10px",
          background: "var(--helio-light-color)",
          padding: "1em 2rem",
        }}
      >
        {products.map((product) => (
          <ProductElement key={product.id} product={product} />
        ))}

        <br />

        <form onSubmit={form.onSubmit(() => handleSubmit())}>
          <Group grow>
            <TextInput
              placeholder="Nom du produit"
              {...form.getInputProps("name")}
            />
            <ActionIcon size={30} onClick={form.onSubmit(() => handleSubmit())}>
              <CirclePlus color="#f39415" size={30}></CirclePlus>
            </ActionIcon>
          </Group>
        </form>
      </div>
    </>
  );
}

export default ProductListUpdate;
