import NotFound from "components/common/NotFound";
import ProtectedRoute from "components/common/ProtectedRoute";
import { Routes, Route } from "react-router-dom";
import PoolList from "./PoolList";
import PoolUpdate from "./PoolUpdate";
import PoolView from "./PoolView";

function Pool() {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute routeRoles={["Admin"]}>
            <PoolList />
          </ProtectedRoute>
        }
      />
      <Route
        path="new"
        element={
          <ProtectedRoute routeRoles={["Admin", "Tech"]} requiresOnline>
            <PoolUpdate />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id"
        element={
          <ProtectedRoute>
            <PoolView />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id/update"
        element={
          <ProtectedRoute routeRoles={["Admin", "Tech"]} requiresOnline>
            <PoolUpdate />
          </ProtectedRoute>
        }
      />
      {/* not found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Pool;
