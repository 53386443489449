import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  TextInput,
  Button,
  Checkbox,
  Accordion,
  Select,
  MultiSelect,
  Text,
  Space,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { css } from "aphrodite";
import { global, view } from "styles";
import poolService from "services/poolService";

function PoolUpdate() {
  // eslint-disable-next-line
  const [pool, setPool] = useState(null);
  const [clientId, setClientId] = useState(null);
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const form = useForm({
    initialValues: {
      accessConditions: "",
      address: "",
      surface: 0,
      volume: 0,
      dimensions: "",
      outdoors: false,
      dehumidifying: false,
      dehumidifierBrand: "",
      structureType: "",
      coatingType: "",
      decompressionWell: false,
      decompressionWellLocation: "",
      overflow: false,
      numberOfSkimmers: 0,
      numberOfPushbacks: 0,
      broomSocket: false,
      bottomDrain: false,
      otherRemarks: "",
      filteringType: "",
      catridgeLocation: "",
      sandMediaType: "",
      otherSandMediaType: "",
      filterBrand: "",
      pumpBrand: "",
      cleaningDevice: false,
      cleaningDeviceBrand: "",
      booster: false,
      boosterBrand: "",
      swimmingAgainstTheCurrent: false,
      swimmingAgainstTheCurrentBrand: "",
      disinfectionType: [],
      liquidChlorineAutomated: false,
      liquidChlorineBrand: "",
      activeOxygeneAutomated: false,
      activeOxygeneBrand: "",
      saltElectrolysisBrand: "",
      phAutomated: false,
      phAutomatedType: "",
      phAutomatedBrand: "",
      analysisChamber: false,
      waterHeatingBrand: "",
      waterHeatingLocation: "",
      coverType: [],
      shelterType: "",
      shelterMobile: false,
      securityDevice: [],
    },
  });

  useEffect(() => {
    if (id) {
      getPool(id);
    } else {
      getClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClient = () => {
    const clientId = location.state?.clientId;
    if (!clientId) {
      navigate("notfound");
      return;
    }
    setClientId(clientId);
  };

  const getPool = async (id) => {
    try {
      const result = await poolService.getPool(id);
      const pool = result.data;
      if (pool.isDeleted) {
        navigate("/poolnotfound");
      }
      setPool(pool);
      updateForm(pool);
    } catch (err) {
      console.error(err);
    }
  };

  const cheatTransformNumbers = (body) => {
    const cheatNumberFields = [
      "surface",
      "volume",
      "numberOfSkimmers",
      "numberOfPushbacks",
    ];

    cheatNumberFields.forEach((field) => {
      body[field] = parseInt(body[field]);
    });
  };

  const updatePool = async (id, body) => {
    cheatTransformNumbers(body);
    try {
      await poolService.updatePool(id, { ...body, client: pool.client });
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  const createPool = async (body) => {
    cheatTransformNumbers(body);
    try {
      await poolService.addPool({ ...body, client: clientId });
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  function updateForm(pool) {
    form.setFieldValue("accessConditions", pool.accessConditions);
    form.setFieldValue("address", pool.address);
    form.setFieldValue("surface", pool.surface);
    form.setFieldValue("volume", pool.volume);
    form.setFieldValue("dimensions", pool.dimensions);
    form.setFieldValue("outdoors", pool.outdoors);
    form.setFieldValue("dehumidifying", pool.dehumidifying);
    form.setFieldValue("dehumidifierBrand", pool.dehumidifierBrand);
    form.setFieldValue("structureType", pool.structureType);
    form.setFieldValue("coatingType", pool.coatingType);
    form.setFieldValue("decompressionWell", pool.decompressionWell);
    form.setFieldValue(
      "decompressionWellLocation",
      pool.decompressionWellLocation
    );
    form.setFieldValue("overflow", pool.overflow);
    form.setFieldValue("numberOfSkimmers", pool.numberOfSkimmers);
    form.setFieldValue("numberOfPushbacks", pool.numberOfPushbacks);
    form.setFieldValue("broomSocket", pool.broomSocket);
    form.setFieldValue("bottomDrain", pool.bottomDrain);
    form.setFieldValue("otherRemarks", pool.otherRemarks);
    form.setFieldValue("filteringType", pool.filteringType);
    form.setFieldValue("catridgeLocation", pool.catridgeLocation);
    form.setFieldValue("sandMediaType", pool.sandMediaType);
    form.setFieldValue("otherSandMediaType", pool.otherSandMediaType);
    form.setFieldValue("filterBrand", pool.filterBrand);
    form.setFieldValue("pumpBrand", pool.pumpBrand);
    form.setFieldValue("cleaningDevice", pool.cleaningDevice);
    form.setFieldValue("cleaningDeviceBrand", pool.cleaningDeviceBrand);
    form.setFieldValue("booster", pool.booster);
    form.setFieldValue("boosterBrand", pool.boosterBrand);
    form.setFieldValue(
      "swimmingAgainstTheCurrent",
      pool.swimmingAgainstTheCurrent
    );
    form.setFieldValue(
      "swimmingAgainstTheCurrentBrand",
      pool.swimmingAgainstTheCurrentBrand
    );
    form.setFieldValue("disinfectionType", pool.disinfectionType || []);
    form.setFieldValue("liquidChlorineAutomated", pool.liquidChlorineAutomated);
    form.setFieldValue("liquidChlorineBrand", pool.liquidChlorineBrand);
    form.setFieldValue("activeOxygeneAutomated", pool.activeOxygeneAutomated);
    form.setFieldValue("activeOxygeneBrand", pool.activeOxygeneBrand);
    form.setFieldValue("saltElectrolysisBrand", pool.saltElectrolysisBrand);
    form.setFieldValue("phAutomated", pool.phAutomated);
    form.setFieldValue("phAutomatedType", pool.phAutomatedType);
    form.setFieldValue("phAutomatedBrand", pool.phAutomatedBrand);
    form.setFieldValue("analysisChamber", pool.analysisChamber);
    form.setFieldValue("waterHeatingBrand", pool.waterHeatingBrand);
    form.setFieldValue("waterHeatingLocation", pool.waterHeatingLocation);
    form.setFieldValue("coverType", pool.coverType);
    form.setFieldValue("shelterType", pool.shelterType);
    form.setFieldValue("shelterMobile", pool.shelterMobile);
    form.setFieldValue("securityDevice", pool.securityDevice);
  }

  function formSubmit() {
    if (id) {
      updatePool(id, form.values);
    } else {
      createPool(form.values);
    }
  }

  const handleNumber = (formFieldChange) => (event) => {
    // validate value
    event.target.value = event.target.value.replace(/\D/g, "");
    event.target.value = parseInt(event.target.value) || 0;

    // call form onChange function
    formFieldChange.onChange(event);
  };

  return (
    <>
      <BackButton />
      <div className={css(view.view)}>
        <h2 className={css(global.title)}>
          {id ? "MODIFICATION FICHE PISCINE" : "NOUVELLE FICHE PISCINE"}
        </h2>
        <form
          className={css(global.form)}
          onSubmit={form.onSubmit(() => formSubmit())}
        >
          <Accordion defaultValue="caracteristics">
            <Accordion.Item value="caracteristics">
              <Accordion.Control>
                A/ CARACTÉRISTIQUES DU BASSIN
              </Accordion.Control>
              <Accordion.Panel>
                <TextInput
                  label="Conditions d'accès"
                  placeholder="Accès"
                  {...form.getInputProps("accessConditions")}
                />
                <TextInput
                  label="Adresse"
                  placeholder="Adresse"
                  {...form.getInputProps("address")}
                />
                <TextInput
                  label="Surface du plan d'eau"
                  {...form.getInputProps("surface")}
                  onChange={handleNumber(form.getInputProps("surface"))}
                />
                <TextInput
                  label="Volume estimé"
                  {...form.getInputProps("volume")}
                  onChange={handleNumber(form.getInputProps("volume"))}
                />
                <TextInput
                  label="Dimensions"
                  placeholder="Dimensions"
                  {...form.getInputProps("dimensions")}
                />
                <Checkbox
                  label="Extérieure"
                  {...form.getInputProps("outdoors", { type: "checkbox" })}
                />
                <Checkbox
                  label="Déshumidification"
                  {...form.getInputProps("dehumidifying", { type: "checkbox" })}
                />
                {form.getInputProps("dehumidifying").value && (
                  <TextInput
                    label="Marque - modèle"
                    placeholder="Marque - modèle"
                    {...form.getInputProps("dehumidifierBrand")}
                  />
                )}
                <TextInput
                  label="Type de structure (coque, polystyrène...)"
                  placeholder="Structure"
                  {...form.getInputProps("structureType")}
                />
                <TextInput
                  label="Type de revêtement (liner, membrane...)"
                  placeholder="Revêtement"
                  {...form.getInputProps("coatingType")}
                />
                <Checkbox
                  label="Puits de décompression"
                  {...form.getInputProps("decompressionWell", {
                    type: "checkbox",
                  })}
                />
                {form.getInputProps("decompressionWell").value && (
                  <TextInput
                    label="Localisation du puit de décompression"
                    placeholder="Localisation"
                    {...form.getInputProps("decompressionWellLocation")}
                  />
                )}
                <Checkbox
                  label="Débordement"
                  {...form.getInputProps("overflow", { type: "checkbox" })}
                />
                <TextInput
                  label="Nombre de skimmers"
                  {...form.getInputProps("numberOfSkimmers")}
                  onChange={handleNumber(
                    form.getInputProps("numberOfSkimmers")
                  )}
                />
                <TextInput
                  label="Nombre de refoulements"
                  {...form.getInputProps("numberOfPushbacks")}
                  onChange={handleNumber(
                    form.getInputProps("numberOfPushbacks")
                  )}
                />
                <Checkbox
                  label="Prise balai"
                  {...form.getInputProps("broomSocket", { type: "checkbox" })}
                />
                <Checkbox
                  label="Bonde de fond"
                  {...form.getInputProps("bottomDrain", { type: "checkbox" })}
                />
                <TextInput
                  label="Remarques complémentaires"
                  placeholder="Autres remarques - dysfonctionnements constatés"
                  {...form.getInputProps("otherRemarks")}
                />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="filtering">
              <Accordion.Control>B/ FILTRATION</Accordion.Control>
              <Accordion.Panel>
                <Select
                  data={["cartouche", "sable", "diatomée"]}
                  label="Type de filtration"
                  placeholder="Type de filtration"
                  clearable
                  {...form.getInputProps("filteringType")}
                />
                {form.getInputProps("filteringType").value === "cartouche" && (
                  <Select
                    data={["sous skimmers", "local technique"]}
                    label="Si cartouche, localisation"
                    placeholder="Localisation cartouche"
                    clearable
                    {...form.getInputProps("catridgeLocation")}
                  />
                )}
                {form.getInputProps("filteringType").value === "sable" && (
                  <>
                    <Select
                      data={["sable", "verre", "zéolithe", "autres"]}
                      label="Si sable, nature du média"
                      placeholder="Nature du média"
                      clearable
                      {...form.getInputProps("sandMediaType")}
                    />
                    {form.getInputProps("sandMediaType").value === "autres" && (
                      <TextInput
                        label="Autres types de média (spécifier)"
                        placeholder="Sable - autre média"
                        {...form.getInputProps("otherSandMediaType")}
                      />
                    )}
                  </>
                )}
                <TextInput
                  label="Filtre marque - modèle"
                  placeholder="Marque du filtre"
                  {...form.getInputProps("filterBrand")}
                />
                <TextInput
                  label="Pompe marque - modèle"
                  placeholder="Marque de la pompe"
                  {...form.getInputProps("pumpBrand")}
                />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="equipment">
              <Accordion.Control>
                C/ ÉQUIPEMENT COMPLÉMENTAIRE
              </Accordion.Control>
              <Accordion.Panel>
                <Checkbox
                  label="Appareil de nettoyage"
                  {...form.getInputProps("cleaningDevice", {
                    type: "checkbox",
                  })}
                />
                {form.getInputProps("cleaningDevice").value && (
                  <TextInput
                    label="Marque - modèle"
                    placeholder="Marque de l'appareil de nettoyage"
                    {...form.getInputProps("cleaningDeviceBrand")}
                  />
                )}
                <Checkbox
                  label="Surpresseur"
                  {...form.getInputProps("booster", { type: "checkbox" })}
                />
                {form.getInputProps("booster").value && (
                  <TextInput
                    label="Marque - modèle"
                    placeholder="Marque du surpresseur"
                    {...form.getInputProps("boosterBrand")}
                  />
                )}
                <Checkbox
                  label="Nage à contre-courant"
                  {...form.getInputProps("swimmingAgainstTheCurrent")}
                />
                {form.getInputProps("swimmingAgainstTheCurrent").value && (
                  <TextInput
                    label="Marque - modèle"
                    placeholder="Marque de la nage à contre-courant"
                    {...form.getInputProps("swimmingAgainstTheCurrentBrand")}
                  />
                )}

                <Space h="md" />
                <Text size="md" weight={700}>
                  1 - Traitement de l'eau
                </Text>
                <MultiSelect
                  data={[
                    "chlore galets",
                    "brome",
                    'oxygène actif "solide"',
                    "biguanide",
                    "ozone",
                    "UV",
                    "chlore liquide",
                    "oxygène actif liquide",
                    "électrolyse sel",
                  ]}
                  label="Désinfection"
                  placeholder="Type de désinfection"
                  {...form.getInputProps("disinfectionType")}
                  clearable
                />

                {form
                  .getInputProps("disinfectionType")
                  .value.includes("chlore liquide") && (
                    <>
                      <Checkbox
                        label="Chlore liquide automatisé"
                        {...form.getInputProps("liquidChlorineAutomated", {
                          type: "checkbox",
                        })}
                      />
                      {form.getInputProps("liquidChlorineAutomated").value && (
                        <TextInput
                          label="Chlore liquide automatisé - marque et modèle"
                          placeholder="Chlore liquide automatisé - marque et modèle"
                          {...form.getInputProps("liquidChlorineBrand")}
                        />
                      )}
                    </>
                  )}
                {form
                  .getInputProps("disinfectionType")
                  .value.includes("oxygène actif liquide") && (
                    <>
                      <Checkbox
                        label="Oxygène actif liquide automatisé"
                        {...form.getInputProps("activeOxygeneAutomated", {
                          type: "checkbox",
                        })}
                      />
                      {form.getInputProps("activeOxygeneAutomated").value && (
                        <TextInput
                          label="Oxygène actif liquide automatisé - marque et modèle"
                          placeholder="Oxygène actif liquide automatisé - marque et modèle"
                          {...form.getInputProps("activeOxygeneBrand")}
                        />
                      )}
                    </>
                  )}
                {form
                  .getInputProps("disinfectionType")
                  .value.includes("électrolyse sel") && (
                    <TextInput
                      label="Électrolyse sel - marque et modèle"
                      placeholder="Électrolyse sel - marque et modèle"
                      {...form.getInputProps("saltElectrolysisBrand")}
                    />
                  )}
                <Checkbox
                  label="Régulation du pH automatisée"
                  {...form.getInputProps("phAutomated", {
                    type: "checkbox",
                  })}
                />
                {form.getInputProps("phAutomated").value && (
                  <>
                    <Select
                      data={["pH +", "pH -"]}
                      label="Régulation automatisée en :"
                      placeholder="Sélectionnez type de régulation"
                      clearable
                      {...form.getInputProps("phAutomatedType")}
                    />
                    <TextInput
                      label="Régulation du pH automatique - marque et modèle"
                      placeholder="Marque du régulateur de pH"
                      {...form.getInputProps("phAutomatedBrand")}
                    />
                  </>
                )}
                <Checkbox
                  label="Chambre d'analyse"
                  {...form.getInputProps("analysisChamber", {
                    type: "checkbox",
                  })}
                />
                <Space h="md" />
                <Text size="md" weight={700}>
                  2 - Chauffage de l'eau
                </Text>
                <TextInput
                  label="Type de chauffage, marque et modèle"
                  placeholder="Marque du chauffage"
                  {...form.getInputProps("waterHeatingBrand")}
                />
                <TextInput
                  label="Localisation"
                  placeholder="Localisation du chauffage"
                  {...form.getInputProps("waterHeatingLocation")}
                />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="security">
              <Accordion.Control>D/ ÉQUIPEMENT DE SÉCURITÉ</Accordion.Control>
              <Accordion.Panel>
                <Text size="md" weight={700}>
                  3 - Couverture
                </Text>
                <MultiSelect
                  data={[
                    "Couverture été (bulles)",
                    "Couverture hiver",
                    "Couvertures à barres",
                    "Rolling-deck",
                    'Couverture type "cover seal"',
                    "Volet automatique hors-sol",
                    "Volet immergé sous caillebotis",
                    "Volet automatique en fond",
                  ]}
                  placeholder="Sélectionnez les types de couverture"
                  {...form.getInputProps("coverType")}
                  clearable
                />
                <Space h="md" />
                <Text size="md" weight={700}>
                  4 - Abri
                </Text>
                <Select
                  data={["Abri haut", "Abri bas", "Abri plat"]}
                  placeholder="Sélectionnez un type d'abri"
                  {...form.getInputProps("shelterType")}
                  clearable
                />
                <Checkbox
                  label="Abri mobile"
                  {...form.getInputProps("shelterMobile", {
                    type: "checkbox",
                  })}
                />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="rules">
              <Accordion.Control>E/ RÈGLEMENTATION</Accordion.Control>
              <Accordion.Panel>
                <Text size="md" weight={700}>
                  Rappel de la règlementation en vigueur :
                </Text>
                <Text align="justify">
                  Aux termes des articles L128-1 et L128-2 du Code de la
                  construction et de habitation, il est notamment prévu que :
                  depuis le 1er janvier 2006 toutes les piscines enterrées non
                  closes privatives à usage individuel ou collectif doivent être
                  pourvues d'un dispositif de sécurité normalisé visant à
                  prévenir le risque de noyade.
                </Text>
                <Text align="justify">
                  L'obligation d'équiper la piscine d'un dispositif de sécurité
                  incombe à son propriétaire exclusivement. De son coté, le
                  constructeur ou l'installateur d'une telle piscine doit
                  fournir au maitre d'ouvrage une note technique indiquant le
                  dispositif de sécurité normalisé retenu
                </Text>
                <Text align="justify">
                  L'installation du dispositif de sécurité doit intervenir, au
                  plus tard, à la mise en eau de la piscine. A défaut, le
                  propriétaire engagerait sa responsabilite, pleine et entière,
                  au regard des possibles conséquences liées à l'absence d'un
                  tel dispositif.
                </Text>
                <Space h="md" />
                <Text size="md" weight={700}>
                  Dispositifs retenus par le client
                </Text>
                <MultiSelect
                  data={[
                    "Barrières de sécurité",
                    "Alarme",
                    "Couverture de sécurité",
                    "Abri de sécurité",
                    "Aucun (client informé de l'obligation d'installation)",
                  ]}
                  placeholder="Sélectionnez les dispositifs de sécurité"
                  {...form.getInputProps("securityDevice")}
                  clearable
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button type="submit">Enregistrer</Button>
          <Button onClick={() => navigate(-1)} color="dark">
            Annuler
          </Button>
        </form>
      </div>
    </>
  );
}

export default PoolUpdate;
