import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  TextInput,
  Textarea,
  Button,
  Checkbox,
  Accordion,
  Select,
  MultiSelect,
  Text,
  Tooltip,
  Group,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DateTimePicker } from "@mantine/dates";
import "dayjs/locale/fr";
import { css } from "aphrodite";
import { global, view } from "styles";
import maintenanceService from "services/maintenanceService";
import userService from "services/userService";
import SuppliedProductUpdate from "components/product/SuppliedProductUpdate";
import notif from "components/common/notification";
import authConstants from "constants/authConstants";
import maintenanceConstants from "constants/maintenanceConstants";
import authService from "services/authService";
import { CircleCheck, CircleX, InfoCircle } from "tabler-icons-react";

function MaintenanceUpdate() {
  const [maintenance, setMaintenance] = useState(null);
  const [poolId, setPoolId] = useState(null);
  const [users, setUsers] = useState([]);
  const [suppliedProducts, setSuppliedProducts] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const form = useForm({
    initialValues: {
      type: "",
      date: new Date(),
      pool: null,
      technicians: [],
      generalAspect: "",
      waterTurbidity: "",
      waterColor: "",
      badProductUsage: "",
      anomalies: "",
      freeChlore: "",
      totalChlore: "",
      combinedChlore: "",
      pH: "",
      temperature: "",
      alk: "",
      cya: "",
      phosph: "",
      brome: "",
      salt: "",
      otherResults: "",
      curativeAction: "",
      hydraulicCircuit: false,
      waterLevelAdjust: false,
      clockSettings: false,
      wallCleaning: false,
      skimmerCleaning: false,
      differentialTest: false,
      waterLineCleaning: false,
      filterCleaning: false,
      chocTreatment: false,
      saltLevelAdjust: false,
      sondeWintering: false,
      shutterCleaning: false,
      cover: false,
      pacPurge: false,
      advice: "",
    },
  });

  useEffect(() => {
    (async () => {
      // get all users
      const result = await userService.findAllUser({
        query: { isDeleted: false },
      });
      if (authService.getUserType() === authConstants.USER_TYPES.Tech) {
        setUsers([
          ...result.data.filter(
            (x) => x.userType === authConstants.USER_TYPES.Tech
          ),
          authService.getConnectedUser(),
        ]);
      } else {
        setUsers(
          result.data.filter(
            (x) => x.userType === authConstants.USER_TYPES.Tech
          )
        );
      }

      if (id) {
        // get maintenance to update
        const result = await maintenanceService.getMaintenance(id);
        const maintenance = result.data;
        if (!maintenance || maintenance.isDeleted) {
          navigate("/maintenancenotfound");
        }
        setMaintenance(maintenance);
        updateForm(maintenance);
      } else {
        // get pool id to create the maintenance
        const poolId = location.state?.poolId;
        if (!poolId) {
          navigate("notfound");
          return;
        }
        setPoolId(poolId);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const updateMaintenance = async (id, body) => {
    try {
      await maintenanceService.updateMaintenance(id, {
        ...body,
        client: maintenance.client,
      });
    } catch (err) {
      if (!navigator.onLine) {
        notif.info(
          "Hors ligne... les modifications seront envoyées dès que la connexion sera de retour"
        );
      } else {
        console.error(err);
      }
    }
  };

  const createMaintenance = async (body) => {
    try {
      console.log({
        ...body,
        pool: poolId,
      });
      const maintenance = await maintenanceService.addMaintenance({
        ...body,
        pool: poolId,
      });
      return maintenance.data;
    } catch (err) {
      console.error(err);
    }
  };

  function updateForm(maintenance) {
    form.setFieldValue("type", maintenance.type);
    form.setFieldValue("date", new Date(maintenance.date));
    form.setFieldValue("pool", maintenance.pool);
    form.setFieldValue("technicians", maintenance.technicians);
    form.setFieldValue("generalAspect", maintenance.generalAspect);
    form.setFieldValue("waterTurbidity", maintenance.waterTurbidity);
    form.setFieldValue("waterColor", maintenance.waterColor);
    form.setFieldValue("badProductUsage", maintenance.badProductUsage);
    form.setFieldValue("anomalies", maintenance.anomalies);
    form.setFieldValue("freeChlore", maintenance.freeChlore);
    form.setFieldValue("totalChlore", maintenance.totalChlore);
    form.setFieldValue("combinedChlore", maintenance.combinedChlore);
    form.setFieldValue("pH", maintenance.pH);
    form.setFieldValue("temperature", maintenance.temperature);
    form.setFieldValue("alk", maintenance.alk);
    form.setFieldValue("cya", maintenance.cya);
    form.setFieldValue("phosph", maintenance.phosph);
    form.setFieldValue("brome", maintenance.brome);
    form.setFieldValue("salt", maintenance.salt);
    form.setFieldValue("otherResults", maintenance.otherResults);
    form.setFieldValue("curativeAction", maintenance.curativeAction);
    form.setFieldValue("hydraulicCircuit", maintenance.hydraulicCircuit);
    form.setFieldValue("waterLevelAdjust", maintenance.waterLevelAdjust);
    form.setFieldValue("clockSettings", maintenance.clockSettings);
    form.setFieldValue("wallCleaning", maintenance.wallCleaning);
    form.setFieldValue("skimmerCleaning", maintenance.skimmerCleaning);
    form.setFieldValue("differentialTest", maintenance.differentialTest);
    form.setFieldValue("waterLineCleaning", maintenance.waterLineCleaning);
    form.setFieldValue("filterCleaning", maintenance.filterCleaning);
    form.setFieldValue("chocTreatment", maintenance.chocTreatment);
    form.setFieldValue("saltLevelAdjust", maintenance.saltLevelAdjust);
    form.setFieldValue("sondeWintering", maintenance.sondeWintering);
    form.setFieldValue("shutterCleaning", maintenance.shutterCleaning);
    form.setFieldValue("cover", maintenance.cover);
    form.setFieldValue("pacPurge", maintenance.pacPurge);
    form.setFieldValue("advice", maintenance.advice);
    setSuppliedProducts(JSON.parse(maintenance.suppliedProducts || []));
  }

  async function formSubmit() {
    try {
      if (id) {
        await updateMaintenance(id, {
          ...form.values,
          suppliedProducts: JSON.stringify(suppliedProducts),
        });
      } else {
        await createMaintenance({
          ...form.values,
          suppliedProducts: JSON.stringify(suppliedProducts),
        });
      }
      navigate(-1);
    } catch (err) {
      console.error(err);
      notif.error(
        "Impossible de créer / mettre à jour la visite d'entretien pour le moment"
      );
    }
  }

  const getMandatoryActionsIcon = () => {
    const fields = [
      form.getInputProps("hydraulicCircuit").value,
      form.getInputProps("waterLevelAdjust").value,
      form.getInputProps("clockSettings").value,
      form.getInputProps("wallCleaning").value,
      form.getInputProps("skimmerCleaning").value,
      form.getInputProps("differentialTest").value,
      form.getInputProps("waterLineCleaning").value,
      form.getInputProps("filterCleaning").value,
    ];
    const done = fields.reduce((prev, curr) => prev + (curr ? 1 : 0), 0);

    return (
      <Tooltip
        label={`${done} sur ${fields.length} réalisées`}
        events={{ touch: true, focus: true, hover: true }}
      >
        <div>
          {done === fields.length ? (
            <CircleCheck color="green" />
          ) : (
            <CircleX color="red" />
          )}
        </div>
      </Tooltip>
    );
  };

  const getOptionalActionsIcon = () => {
    const fields = [
      form.getInputProps("chocTreatment").value,
      form.getInputProps("saltLevelAdjust").value,
      form.getInputProps("sondeWintering").value,
      form.getInputProps("shutterCleaning").value,
      form.getInputProps("cover").value,
      form.getInputProps("pacPurge").value,
    ];
    const done = fields.reduce((prev, curr) => prev + (curr ? 1 : 0), 0);

    return (
      <Tooltip
        label={`${done} sur ${fields.length} réalisées`}
        events={{ touch: true, focus: true, hover: true }}
      >
        <div>
          {done === fields.length ? (
            <CircleCheck color="green" />
          ) : (
            <InfoCircle color="orange" />
          )}
        </div>
      </Tooltip>
    );
  };

  const getMaintenanceStatus = () => {
    return maintenance?.status;
  };

  const getMaintenanceTechnicians = () => {
    return maintenance?.technicians;
  };

  const getFormTechnicians = () => {
    return form.getInputProps("technicians").value;
  };

  const createStatus = () => {
    form.values.status = maintenanceConstants.STATUS.CREATED;
    formSubmit();
  };

  const assignStatus = () => {
    form.values.status = maintenanceConstants.STATUS.ASSIGNED;
    formSubmit();
  };

  const submitStatus = () => {
    form.values.status = maintenanceConstants.STATUS.SUBMITTED;
    formSubmit();
  };

  const tobebilledStatus = () => {
    form.values.status = maintenanceConstants.STATUS.TOBEBILLED;
    formSubmit();
  };

  const rejectStatus = () => {
    form.values.status = maintenanceConstants.STATUS.REJECTED;
    formSubmit();
  };

  const billedStatus = () => {
    form.values.status = maintenanceConstants.STATUS.BILLED;
    formSubmit();
  };

  const getActionButton = () => {
    // variables
    const userType = authService.getUserType();
    const USER_TYPES = authConstants.USER_TYPES;
    const maintenanceStatus = getMaintenanceStatus();
    const STATUS = maintenanceConstants.STATUS;

    // helper
    const equals = (a, b) =>
      a.length === b.length && a.every((x) => b.includes(x));

    // admin case
    if (userType === USER_TYPES.Admin) {
      switch (maintenanceStatus) {
        case null || undefined:
          if (!getFormTechnicians().length) {
            return <Button onClick={createStatus}>Créer</Button>;
          } else {
            return <Button onClick={assignStatus}>Créer et affecter</Button>;
          }
        case STATUS.CREATED:
          if (!getFormTechnicians().length) {
            return (
              <Button disabled onClick={assignStatus}>
                Sélectionnez un technicien
              </Button>
            );
          } else {
            return <Button onClick={assignStatus}>Affecter</Button>;
          }
        case STATUS.ASSIGNED:
        case STATUS.REJECTED:
        case STATUS.TOBEBILLED:
          if (!equals(getMaintenanceTechnicians(), getFormTechnicians())) {
            return <Button onClick={assignStatus}>Réaffecter</Button>;
          } else {
            return (
              <Button onClick={billedStatus}>
                Passer en facturation
              </Button>
            );
          }
        case STATUS.SUBMITTED:
          return (
            <>
              <Button onClick={tobebilledStatus}>Valider</Button>
              <Button color="alert" onClick={rejectStatus}>
                Rejeter
              </Button>
            </>
          )
        case STATUS.BILLED:
          return (
            <>
              <Button onClick={formSubmit}>Enregistrer les modifications</Button>
            </>
          );
        default:
          return <p>État de la visite d'entretien inconnu...</p>;
      }
    }

    // tech case
    if (userType === USER_TYPES.Tech) {
      switch (maintenanceStatus) {
        case STATUS.ASSIGNED:
        case STATUS.REJECTED:
          return <Button onClick={submitStatus}>Soumettre</Button>;
        case STATUS.SUBMITTED:
          return (
            <Button onClick={formSubmit}>Enregistrer les modifications</Button>
          );
        default:
          return <Button disabled>Aucune action attendue...</Button>;
      }
    }
  };

  return (
    <>
      <BackButton />
      <div className={css(view.view)}>
        <h2 className={css(global.title)}>
          {id
            ? "MODIFICATION FICHE VISITE D'ENTRETIEN"
            : "NOUVELLE FICHE VISITE D'ENTRETIEN"}
        </h2>
        <form
          className={css(global.form)}
          onSubmit={form.onSubmit(() => formSubmit())}
        >
          <Select
            data={["estivage 1", "estivage 2", "hivernage", "intermédiaire"]}
            label="Type de visite"
            placeholder="Type de visite"
            clearable
            {...form.getInputProps("type")}
          />
          <DateTimePicker
            locale="fr"
            placeholder="Choisissez la date de visite"
            label="Date et heure"
            {...form.getInputProps("date")}
          />
          {/* <TimeInput label="Heure" {...form.getInputProps("date")} /> */}

          {authService.getUserType() === authConstants.USER_TYPES.Admin ? (
            <MultiSelect
              data={
                users && users.length
                  ? users.map((r) => ({ value: r.id, label: r.username }))
                  : []
              }
              clearable
              label="Techniciens"
              placeholder="Sélectionnez les techniciens"
              {...form.getInputProps("technicians")}
            />
          ) : (
            <MultiSelect
              data={
                users && users.length
                  ? users.map((r) => ({ value: r.id, label: r.username }))
                  : []
              }
              disabled
              label="Techniciens"
              placeholder="Sélectionnez les techniciens"
              {...form.getInputProps("technicians")}
            />
          )}
          <Text weight={800}>Aspect général de l'installation</Text>
          <Textarea
            autosize
            minRows={2}
            radius="md"
            label="Aspect général du bassin - observations"
            placeholder="Aspect général"
            {...form.getInputProps("generalAspect")}
          />
          <TextInput
            label="Turbidité de l'eau"
            placeholder="Turbitidé"
            {...form.getInputProps("waterTurbidity")}
          />

          <TextInput
            label="Couleur"
            placeholder="Couleur"
            {...form.getInputProps("waterColor")}
          />
          <Textarea
            autosize
            minRows={2}
            radius="md"
            label="Utilisation de produit(s) d'entretien et/ou traitement inapproprié(s)"
            placeholder="Produits inappropriés"
            {...form.getInputProps("badProductUsage")}
          />
          <Textarea
            autosize
            minRows={2}
            radius="md"
            label="Anomalies constatées"
            placeholder="Anomalies constatées"
            {...form.getInputProps("anomalies")}
          />
          <Accordion>
            <Accordion.Item value="results">
              <Accordion.Control>RÉSULTATS D'ANALYSE</Accordion.Control>
              <Accordion.Panel>
                <TextInput
                  label="Chlore Libre"
                  placeholder="Chlore Libre"
                  {...form.getInputProps("freeChlore")}
                />
                <TextInput
                  label="Chlore Total"
                  placeholder="Chlore Total"
                  {...form.getInputProps("totalChlore")}
                />
                <TextInput
                  label="Chlore combiné"
                  placeholder="Chlore combiné"
                  {...form.getInputProps("combinedChlore")}
                />
                <TextInput
                  label="PH"
                  placeholder="PH"
                  {...form.getInputProps("pH")}
                />
                <TextInput
                  label="Température"
                  placeholder="Température"
                  {...form.getInputProps("temperature")}
                />
                <TextInput
                  label="Alk"
                  placeholder="Alk"
                  {...form.getInputProps("alk")}
                />
                <TextInput
                  label="Cya"
                  placeholder="Cya"
                  {...form.getInputProps("cya")}
                />
                <TextInput
                  label="Phosph"
                  placeholder="Phosph"
                  {...form.getInputProps("phosph")}
                />
                <TextInput
                  label="Brome"
                  placeholder="Brome"
                  {...form.getInputProps("brome")}
                />
                <TextInput
                  label="Sel"
                  placeholder="Sel"
                  {...form.getInputProps("salt")}
                />
                <TextInput
                  label="Autre si pertinent"
                  placeholder="Autre si pertinent"
                  {...form.getInputProps("otherResults")}
                />
                <Text weight={800}>
                  Actions curatives suite à analyse ci-dessus
                </Text>
                <Textarea
                  autosize
                  minRows={2}
                  radius="md"
                  placeholder="Actions curatives"
                  {...form.getInputProps("curativeAction")}
                />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="mandatory">
              <Accordion.Control>
                <Group noWrap>
                  {getMandatoryActionsIcon()}
                  <p>ACTIONS À EFFECTUER À CHAQUE VISITE</p>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Checkbox
                  label="Vérification circuit hydraulique"
                  {...form.getInputProps("hydraulicCircuit", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Ajustement niveau d'eau"
                  {...form.getInputProps("waterLevelAdjust", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Réglage de l'horloge"
                  {...form.getInputProps("clockSettings", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Nettoyage fond/parois"
                  {...form.getInputProps("wallCleaning", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Nettoyage skimmers"
                  {...form.getInputProps("skimmerCleaning", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Test différentiel"
                  {...form.getInputProps("differentialTest", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Nettoyage ligne d'eau"
                  {...form.getInputProps("waterLineCleaning", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Lavage de filtre et préfiltre"
                  {...form.getInputProps("filterCleaning", {
                    type: "checkbox",
                  })}
                />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="optional">
              <Accordion.Control>
                <Group noWrap>
                  {getOptionalActionsIcon()}
                  <p>
                    ACTIONS SUPPLÉMENTAIRES À EFFECTUER EN ESTIVAGE ET/OU
                    HIVERNAGE
                  </p>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Checkbox
                  label="Traitement choc"
                  {...form.getInputProps("chocTreatment", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Ajustement niveau de sel"
                  {...form.getInputProps("saltLevelAdjust", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Hivernage sondes remises client"
                  {...form.getInputProps("sondeWintering", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Nettoyage volet (optionnel)"
                  {...form.getInputProps("shutterCleaning", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Couverture été/hiver"
                  {...form.getInputProps("cover", {
                    type: "checkbox",
                  })}
                />
                <Checkbox
                  label="Mise en route / purge pac"
                  {...form.getInputProps("pacPurge", {
                    type: "checkbox",
                  })}
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <SuppliedProductUpdate
            values={suppliedProducts}
            setValues={setSuppliedProducts}
          />

          <Textarea
            label="Conseils donnés au client"
            autosize
            minRows={2}
            radius="md"
            placeholder="Conseils"
            maxLength={500}
            {...form.getInputProps("advice")}
          />

          {getActionButton()}

          <Button onClick={() => navigate(-1)} color="dark">
            Annuler
          </Button>
        </form>
      </div>
    </>
  );
}

export default MaintenanceUpdate;
