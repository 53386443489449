import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ActionIcon, Button, Modal, Tabs, Tooltip } from "@mantine/core";
import { css } from "aphrodite";
import { view, global, cardList } from "styles";
import clientService from "services/clientService";
import ProtectedRoute from "components/common/ProtectedRoute";
import clientContractService from "services/clientContractService";
import { AlertCircle, CirclePlus } from "tabler-icons-react";
import poolService from "services/poolService";
import PoolCard from "components/pool/PoolCard";
import InfoLine from "components/common/InfoLine";
import authService from "services/authService";
import Unauthorized from "components/common/Unauthorized";

function ClientView() {
  const [opened, setOpened] = useState(false);
  const [client, setClient] = useState(null);
  const [pools, setPools] = useState([]);
  const [clientContract, setClientContract] = useState(null);
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getInfo(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInfo = async (id) => {
    try {
      // if client tries to see another one
      if (
        authService.getConnectedUser().userType === 3 &&
        id !== authService.getConnectedUser().client.toString()
      ) {
        return;
      }

      // get client
      let result = await clientService.getClient(id);
      const client = result.data;
      if (!client || client.isDeleted) {
        navigate("/clientnotfound");
      }
      setClient(client);

      // get contract
      if (client.contract) {
        result = await clientContractService.getClientContract(client.contract);
        setClientContract(result.data);
      }

      // get client pools
      const query = { client: parseInt(id), isDeleted: false };
      result = await poolService.findAllPool({ query });
      setPools(result.data);

    } catch (err) {
      console.error(err);
    }
  };

  const deleteClient = async (id) => {
    try {
      await clientService.softDeleteClient(id);
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  if (
    authService.getConnectedUser().userType === 3 &&
    id !== authService.getConnectedUser().client.toString()
  ) {
    return <Unauthorized />;
  }

  return (
    <>
      <BackButton />
      <div className={css(view.view)}>
        <h2 className={css(global.title)}>FICHE CLIENT</h2>
        {client ? (
          <Tabs
            className={css(view.info)}
            variant="pills"
            radius={"sm"}
            defaultValue="informations"
          >
            <Tabs.List>
              <Tabs.Tab value="informations">
                <b>Informations</b>
              </Tabs.Tab>
              <Tabs.Tab value="piscines">
                <b>{`Piscines (${pools.length})`}</b>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="informations">
              <ClientInfo
                client={client}
                contract={clientContract}
                handleModify={() => navigate("update")}
                handleDelete={() => setOpened(true)}
              />
            </Tabs.Panel>
            <Tabs.Panel value="piscines">
              <div className={css(cardList.header)}>
                <ProtectedRoute
                  routeRoles={["Admin", "Tech"]}
                  blockingComponent={<></>}
                >
                  <ProtectedRoute
                    requiresOnline
                    blockingComponent={
                      <Button disabled leftIcon={<CirclePlus />}>
                        Créer une piscine
                      </Button>
                    }
                  >
                    <Link to={"/pool/new"} state={{ clientId: client.id }}>
                      <Button leftIcon={<CirclePlus />}>
                        Créer une piscine
                      </Button>
                    </Link>
                  </ProtectedRoute>
                </ProtectedRoute>
              </div>
              <div className={css(cardList.list)}>
                {pools.map((pool) => {
                  return <PoolCard key={pool.id} pool={pool} />;
                })}
              </div>
            </Tabs.Panel>
          </Tabs>
        ) : (
          <p>
            Impossible de récupérer les informations client pour l'instant...
          </p>
        )}
      </div>

      <DeleteModal
        opened={opened}
        setOpened={setOpened}
        handleClick={() => deleteClient(id)}
      />
    </>
  );
}

function ClientInfo({ client, contract, handleModify, handleDelete }) {
  return (
    <div className={css(view.info)}>
      <InfoLine label="Prénom :" value={client.firstName} />
      <InfoLine label="Nom :" value={client.lastName} />
      <InfoLine label="Email :" value={client.email} />
      <InfoLine label="Téléphone :" value={client.phone} />
      <InfoLine label="Adresse :" value={client.address} />
      <InfoLine label="Contrat :">
        <div className={css(view.value)}>
          <p>{contract?.description || "..."}</p>
          {contract?.isDeleted && (
            <Tooltip
              label="Ce contrat n'existe plus, veuillez en sélectionner un autre"
              events={{ touch: true, focus: true, hover: true }}
              multiline
              width={250}
            >
              <ActionIcon>
                <AlertCircle color="red" />
              </ActionIcon>
            </Tooltip>
          )}
        </div>
      </InfoLine>

      <ProtectedRoute routeRoles={["Admin"]} blockingComponent={<></>}>
        <Button fullWidth onClick={handleModify} color="dark">
          Modifier
        </Button>
        <Button fullWidth color="alert" onClick={handleDelete}>
          Supprimer
        </Button>
      </ProtectedRoute>
    </div>
  );
}

function DeleteModal({ opened, setOpened, handleClick }) {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Voulez-vous vraiment supprimer ce client ?"
    >
      <div className={css(view.modalButtons)}>
        <Button onClick={handleClick} color="alert">
          Supprimer
        </Button>
        <Button onClick={() => setOpened(false)} color="dark">
          Annuler
        </Button>
      </div>
    </Modal>
  );
}

export default ClientView;
