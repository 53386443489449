import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "@mantine/core";
import userService from "services/userService";
import { css } from "aphrodite";
import { global, view } from "styles";
import authConstants from "constants/authConstants";
import ProtectedRoute from "components/common/ProtectedRoute";

function UserView() {
  const [opened, setOpened] = useState(false);
  const [user, setUser] = useState(null);
  const { id } = useParams();
  const state = useLocation().state;

  const navigate = useNavigate();

  useEffect(() => {
    if (state && state.user) {
      setUser(state.user);
    } else {
      loadAll(parseInt(id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAll = async (id) => {
    try {
      const userResult = await userService.getUser(id);
      setUser(userResult.data);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteUser = async (id) => {
    try {
      await userService.softDeleteUser(id);
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <BackButton />
      <div className={css(view.view)}>
        <h2 className={css(global.title)}>FICHE UTILISATEUR</h2>
        {user && (
          <UserInfo
            user={user}
            handleModify={() => navigate("update")}
            handleDelete={() => setOpened(true)}
          />
        )}
      </div>

      <DeleteModal
        opened={opened}
        setOpened={setOpened}
        handleClick={() => deleteUser(id)}
      />
    </>
  );
}

function UserInfo({ user, handleModify, handleDelete }) {
  return (
    <div className={css(view.info)}>
      {/* <UserInfoLine label="deleted :" value={user.isDeleted} /> */}
      <UserInfoLine label="Prénom :" value={user.firstName} />
      <UserInfoLine label="Nom :" value={user.lastName} />
      <UserInfoLine label="Identifiant :" value={user.username} />
      {/* <UserInfoLine label="Email :" value={user.email} /> */}
      <UserInfoLine label="Téléphone :" value={user.mobileNo} />
      <UserInfoLine
        label="Rôle :"
        value={Object.keys(authConstants.USER_TYPES).find(
          (key) => authConstants.USER_TYPES[key] === user.userType
        )}
      />

      <ProtectedRoute
        requiresOnline
        blockingComponent={
          <>
            <Button fullWidth disabled color="dark">
              Modifier
            </Button>
            <Button fullWidth color="alert" disabled>
              Supprimer
            </Button>
          </>
        }
      >
        <Button fullWidth onClick={handleModify} color="dark">
          Modifier
        </Button>
        <Button fullWidth color="alert" onClick={handleDelete}>
          Supprimer
        </Button>
      </ProtectedRoute>
    </div>
  );
}

function UserInfoLine({ label, value }) {
  return (
    <div className={css(view.infoLine)}>
      <p className={css(view.label)}>
        <b>{label}</b>
      </p>
      <p className={css(view.value)}>{value || "..."}</p>
    </div>
  );
}

function DeleteModal({ opened, setOpened, handleClick }) {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Voulez-vous vraiment supprimer cet utilisateur ?"
    >
      <div className={css(view.modalButtons)}>
        <Button onClick={handleClick} color="alert">
          Supprimer
        </Button>
        <Button onClick={() => setOpened(false)} color="dark">
          Annuler
        </Button>
      </div>
    </Modal>
  );
}

export default UserView;
