import { configureStore } from "@reduxjs/toolkit";
import pizzaReducer from "./pizzaSlice";
import authReducer from "./authState";

export const store = configureStore({
  reducer: {
    pizza: pizzaReducer,
    auth: authReducer,
  },
});
