import authConstants from "constants/authConstants";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import authService from "services/authService";
import OnlineRequired from "./OnlineRequired";
import Unauthorized from "./Unauthorized";

function ProtectedRoute({
  routeRoles = [],
  blockingComponent = <Unauthorized />,
  requiresOnline = false,
  children,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  // check auth
  const auth = authService.isAuthenticated();
  if (!auth) {
    return <Navigate to="/login" />;
  }

  // check user type
  const userType = authService.getConnectedUser().userType;
  if (
    routeRoles.length &&
    !routeRoles.map((r) => authConstants.USER_TYPES[r]).includes(userType)
  ) {
    return blockingComponent;
  }

  // offline mode
  function handleConnection() {
    navigate(location.pathname);
  }
  window.addEventListener("online", handleConnection);
  window.addEventListener("offline", handleConnection);

  if (requiresOnline && !navigator.onLine) {
    return blockingComponent !== <Unauthorized /> ? (
      blockingComponent
    ) : (
      <OnlineRequired />
    );
  }

  return children;
}

export default ProtectedRoute;
