import { Group, Text, TextInput } from "@mantine/core";
import notif from "components/common/notification";
import { useEffect, useState } from "react";
import productService from "services/productService";
import ProductSelect from "./ProductSelect";

const SuppliedProductUpdate = ({ values, setValues }) => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    // get all products
    productService
      .findAllProduct()
      .then((res) => {
        setProducts(res.data);
      })
      .catch(() => {
        notif.error("Impossible de récupérer la liste des produits");
      });

    // set selected products
    setSelectedProducts(values.map((x) => x.id));
  }, [values]);

  const handleProductSelectChange = (selection) => {
    // handle delete
    const productIdsRemoved = selectedProducts.filter(
      (x) => !selection.includes(x)
    );

    // handle create
    const productIdsAdded = selection.filter(
      (x) => !selectedProducts.includes(x)
    );

    setValues((current) => [
      ...current.filter((x) => !productIdsRemoved.includes(x.id)),
      ...productIdsAdded.map((id) => ({ id, quantity: "" })),
    ]);

    // set selection
    setSelectedProducts(selection);
  };

  const SuppliedProduct = ({ suppliedProduct }) => {
    const [quantity, setQuantity] = useState("");

    useEffect(() => {
      setQuantity(suppliedProduct.quantity);
    }, [suppliedProduct.quantity]);

    const updateQuantity = (value) => {
      setQuantity(value);
      suppliedProduct.quantity = value;
    };

    return (
      <Group>
        <Text>{products.find((x) => x.id === suppliedProduct.id)?.name} :</Text>
        <TextInput
          placeholder="Quantité"
          value={quantity}
          onChange={(event) => updateQuantity(event.currentTarget.value)}
        />
      </Group>
    );
  };

  return (
    <>
      <ProductSelect
        value={selectedProducts}
        onChange={handleProductSelectChange}
      />
      {values.map((suppliedProduct) => (
        <SuppliedProduct
          key={suppliedProduct.id}
          suppliedProduct={suppliedProduct}
        />
      ))}
    </>
  );
};

export default SuppliedProductUpdate;
