import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const userUrl = "/client/api/v1/user";

const getUser = async (id) => {
  const url = `${baseUrl}${userUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addUser = async (body) => {
  const url = `${baseUrl}${userUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of users
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of users
 */
const findAllUser = async (body) => {
  const url = `${baseUrl}${userUrl}/list`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeleteUser = async (id) => {
  const url = `${baseUrl}${userUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdateUser = async (id, body) => {
  const url = `${baseUrl}${userUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updateUser = async (id, body) => {
  const url = `${baseUrl}${userUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const changePassword = async (body) => {
  const url = `${baseUrl}${userUrl}/change-password`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const userService = {
  getUser,
  addUser,
  findAllUser,
  softDeleteUser,
  partialUpdateUser,
  updateUser,
  changePassword,
};

export default userService;
