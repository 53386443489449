import { X } from "tabler-icons-react";

const { MultiSelect } = require("@mantine/core");
const { showNotification } = require("@mantine/notifications");
const { useState, useEffect } = require("react");
const { default: productService } = require("services/productService");

function ProductSelect({ onChange, value }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    productService
      .findAllProduct({ query: { isDeleted: false } })
      .then((res) => {
        setProducts(res.data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch(() => {
        showNotification({
          autoClose: 5000,
          message: "Impossible de récupérer la liste des produits",
          color: "alert",
          icon: <X />,
        });
      });
  }, []);

  return (
    <MultiSelect
      data={products.map((p) => ({ value: p.id, label: p.name }))}
      label="Produits fournis au client"
      placeholder="Sélectionnez les produits"
      clearable
      searchable
      onChange={onChange}
      value={value}
    />
  );
}

export default ProductSelect;
