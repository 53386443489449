import BackButton from "components/common/BackButton";
import { useState } from "react";
import { useForm } from "@mantine/form";
import userService from "services/userService";
import { Button, Group, Notification, PasswordInput } from "@mantine/core";
import { Check } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { css } from "aphrodite";
import { account, global } from "styles";
import ProtectedRoute from "components/common/ProtectedRoute";

function AccountSettings() {
  const [successPopup, setSuccessPopup] = useState(false);

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordValidation: "",
    },
    validate: {
      oldPassword: (value) => (value === "" ? "champ requis" : null),
      newPassword: (value) => (value === "" ? "champ requis" : null),
      newPasswordValidation: (value) => (value === "" ? "champ requis" : null),
    },
  });

  const changePassword = async (values) => {
    if (values.newPassword !== values.newPasswordValidation) {
      form.setFieldError(
        "newPasswordValidation",
        <p>La vérification est différente du nouveau mot de passe</p>
      );
      return;
    }
    try {
      const result = await userService.changePassword(values);
      handleStatus(result);
    } catch (err) {
      console.error(err);
    }
  };

  function handleStatus(res) {
    // eslint-disable-next-line
    if (res.status === "SUCCESS") {
      setSuccessPopup(true);
      form.reset();
      setTimeout(() => setSuccessPopup(false), 3000);
    } else if (res.status === "FAILURE") {
      if (res.message === "Incorrect Old Password") {
        form.setFieldError("oldPassword", <p>Mot de passe incorrect</p>);
      }
    }
  }

  return (
    <>
      {successPopup && (
        <Notification
          className={css(account.notif)}
          radius="sm"
          icon={<Check size={18} />}
          disallowClose
          color="primary"
          title="Validé"
        >
          Votre mot de passe a bien été modifié
        </Notification>
      )}
      <BackButton />
      <div className={css(account.account)}>
        <h2 className={css(global.title)}>PARAMÈTRES DU COMPTE</h2>
        <form
          className={css(global.form)}
          onSubmit={form.onSubmit((values) => changePassword(values))}
        >
          <PasswordInput
            label="Ancien mot de passe"
            placeholder="Ancien mot de passe"
            required
            {...form.getInputProps("oldPassword")}
          />
          <PasswordInput
            label="Nouveau mot de passe"
            placeholder="Nouveau mot de passe"
            required
            {...form.getInputProps("newPassword")}
          />
          <PasswordInput
            label="Répétez le nouveau mot de passe"
            placeholder="Répétez le nouveau mot de passe"
            required
            {...form.getInputProps("newPasswordValidation")}
          />

          <Button type="submit">Envoyer</Button>
        </form>

        <ProtectedRoute routeRoles={["Admin"]} blockingComponent={<></>}>
          <>
            <h2 className={css(global.title)}>ADMINISTRATION</h2>
            <Group>
              <p>
                Lister, modifier et ajouter des utilisateurs de l'application
              </p>
              <Button onClick={() => navigate("/user")} color="dark">
                Gérer les utilisateurs
              </Button>
            </Group>
            <br />
            <Group>
              <p>
                Lister, modifier et ajouter les produits des visites d'entretien
              </p>
              <Button onClick={() => navigate("/product")} color="dark">
                Gérer les produits
              </Button>
            </Group>
          </>
        </ProtectedRoute>

        {/* {authService.getUserRoles().includes("ADMIN") && (
          <>
            <h2 className={css(global.title)}>ADMINISTRATION</h2>
            <div>
              <p>
                lister, modifier et ajouter des utilisateur de l'application
              </p>
              <Button onClick={() => navigate("/user")} color="dark">
                gérer les utilisateurs
              </Button>
            </div>
          </>
        )} */}
      </div>
    </>
  );
}

export default AccountSettings;