import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextInput, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { css } from "aphrodite";
import { global, view } from "styles";
import clientService from "services/clientService";
import { ClientContractSelect } from "components/contract/ClientContractSelect";

function ClientUpdate() {
  // eslint-disable-next-line
  const [client, setClient] = useState(null);
  const [contractValue, setContractValue] = useState(0);
  const { id } = useParams();

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      contract: null,
    },
  });

  useEffect(() => {
    if (id) {
      getClient(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClient = async (id) => {
    try {
      const result = await clientService.getClient(id);
      const client = result.data;
      if (client.isDeleted) {
        navigate("/clientnotfound");
      }
      setClient(client);
      updateForm(client);
    } catch (err) {
      console.error(err);
    }
  };

  const updateClient = async (id, body) => {
    // return;
    try {
      await clientService.updateClient(id, body);
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  const createClient = async (body) => {
    try {
      await clientService.addClient(body);
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  function updateForm(client) {
    form.setFieldValue("firstName", client.firstName);
    form.setFieldValue("lastName", client.lastName);
    form.setFieldValue("email", client.email);
    form.setFieldValue("phone", client.phone);
    form.setFieldValue("address", client.address);
    setContractValue(String(client.contract));
  }

  function formSubmit() {
    if (id) {
      updateClient(id, form.values);
    } else {
      createClient(form.values);
    }
  }

  function handleContractChange(selectedOption) {
    form.setFieldValue("contract", parseInt(selectedOption));
    setContractValue(String(selectedOption));
  }

  return (
    <>
      <BackButton />
      <div className={css(view.view)}>
        <h2 className={css(global.title)}>
          {id ? "MODIFICATION FICHE CLIENT" : "NOUVELLE FICHE CLIENT"}
        </h2>
        <form
          className={css(global.form)}
          onSubmit={form.onSubmit(() => formSubmit())}
        >
          <TextInput
            label="Prénom"
            placeholder="Prénom"
            {...form.getInputProps("firstName")}
          />
          <TextInput
            label="Nom"
            placeholder="Nom"
            {...form.getInputProps("lastName")}
          />
          <TextInput
            label="Email"
            placeholder="adresse@email.com"
            {...form.getInputProps("email")}
          />
          <TextInput
            label="Téléphone"
            placeholder="06 45 78 84 95"
            {...form.getInputProps("phone")}
          />
          <TextInput
            label="Adresse"
            placeholder="Adresse postale"
            {...form.getInputProps("address")}
          />
          <ClientContractSelect
            valueProp={contractValue}
            changeCallback={handleContractChange}
            {...form.getInputProps("contract")}
          />

          <Button type="submit">Enregistrer</Button>
          <Button onClick={() => navigate(-1)} color="dark">
            Annuler
          </Button>
        </form>
      </div>
    </>
  );
}

export default ClientUpdate;
