import NotFound from "components/common/NotFound";
import ProtectedRoute from "components/common/ProtectedRoute";
import { Routes, Route } from "react-router-dom";
import MaintenanceList from "./MaintenanceList";
import MaintenanceUpdate from "./MaintenanceUpdate";
import MaintenanceView from "./MaintenanceView";

function Maintenance() {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute routeRoles={["Admin"]}>
            <MaintenanceList />
          </ProtectedRoute>
        }
      />
      <Route
        path="new"
        element={
          <ProtectedRoute routeRoles={["Admin"]} requiresOnline>
            <MaintenanceUpdate />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id"
        element={
          <ProtectedRoute>
            <MaintenanceView />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id/update"
        element={
          <ProtectedRoute>
            <MaintenanceUpdate />
          </ProtectedRoute>
        }
      />
      {/* not found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Maintenance;
