import authService from "./authService";
import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const fileUploadUrl = "/client/api/v1/upload";

/**
 *
 * @param {*} body contains all files
 * @returns
 */
const upload = async (body) => {
  const url = `${baseUrl}${fileUploadUrl}`;
  const response = await fetch(url, {
    method: "POST",
    enctype: "multipart/form-data",
    headers: { Authorization: `Bearer ${authService.getUserToken()}` },
    body,
  });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Return the image blob new url
 *
 * @param {*} url
 */
const download = async (url) => {
  const response = await fetchWrapper().get(url);
  const result = await response.blob();

  if (!response.ok) {
    throw response;
  }

  return URL.createObjectURL(result);
};

const uploadService = {
  upload,
  download,
};

export default uploadService;
