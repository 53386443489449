import { ActionIcon, Button, Group, Modal, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import maintenancePhotoService from "services/maintenancePhotoService";
import uploadService from "services/uploadService";
import { CircleX } from "tabler-icons-react";

import urlServiceProvider from "services/urlServiceProvider";
import ProtectedRoute from "components/common/ProtectedRoute";

const baseUrl = urlServiceProvider.baseUrl;

function MaintenancePhotoView({ photo, onPhotoDeleted }) {
  const [imageUrl, setImageUrl] = useState("");
  const [deleteDialogueOpen, setDeleteDialogueOpen] = useState(false);

  useEffect(() => {
    uploadService
      .download(baseUrl + photo.url)
      .then(setImageUrl)
      .catch(console.error);
  }, [photo]);

  const deletePhoto = () => {
    maintenancePhotoService
      .softDeleteMaintenancePhoto(photo.id)
      .then(() => onPhotoDeleted(photo.id));
  };

  return (
    <>
      <Modal
        opened={deleteDialogueOpen}
        withCloseButton={false}
        onClose={() => setDeleteDialogueOpen(false)}
      >
        <Text>Voulez-vous vraiment supprimer cette image ?</Text>
        <br />
        <Group grow>
          <Button onClick={() => setDeleteDialogueOpen(false)} color="dark">
            Non
          </Button>
          <Button
            onClick={() => {
              setDeleteDialogueOpen(false);
              deletePhoto();
            }}
            color="alert"
          >
            Oui
          </Button>
        </Group>
      </Modal>

      <div style={{ position: "relative", width: "200px" }}>
        <img
          src={imageUrl}
          alt="maintenance"
          width="200"
          height="200"
          onClick={() => window.open(imageUrl, "_blank")}
          style={{ objectFit: "cover", borderRadius: "1rem" }}
        />

        <ProtectedRoute
          routeRoles={["Admin", "Tech"]}
          blockingComponent={<></>}
        >
          <ActionIcon
            onClick={() => setDeleteDialogueOpen(true)}
            variant="filled"
            color="light"
            style={{ position: "absolute", top: "-5px", right: "-5px" }}
          >
            <CircleX color="red" />
          </ActionIcon>
        </ProtectedRoute>
      </div>
    </>
  );
}

export default MaintenancePhotoView;
