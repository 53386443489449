import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const clientContractUrl = "/client/api/v1/clientcontract";

const getClientContract = async (id) => {
  const url = `${baseUrl}${clientContractUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addClientContract = async (body) => {
  const url = `${baseUrl}${clientContractUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of client contracts
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of client contracts
 */
const findAllClientContract = async (body) => {
  const url = `${baseUrl}${clientContractUrl}/list`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeleteClientContract = async (id) => {
  const url = `${baseUrl}${clientContractUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdateClientContract = async (id, body) => {
  const url = `${baseUrl}${clientContractUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updateClientContract = async (id, body) => {
  const url = `${baseUrl}${clientContractUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const clientContractService = {
  getClientContract,
  addClientContract,
  findAllClientContract,
  softDeleteClientContract,
  partialUpdateClientContract,
  updateClientContract,
};

export default clientContractService;
