import { TextInput } from "@mantine/core";
import { ChevronUp, ChevronDown, Selector } from "tabler-icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react";
import maintenanceService from "services/maintenanceService";
import MaintenanceTable from "components/maintenance/MaintenanceTable";
import maintenanceConstants from "constants/maintenanceConstants";
import authService from "services/authService";
import { css } from "aphrodite";
import { global } from "styles";
import tablePage from "styles/table-page";
import { Pagination } from '@mantine/core';

// Number of table lines per page
const PAGE_SIZE = 10;

// Date order by which maintenances are sorted
let DATE_ORDER = "desc";

const STATUS = maintenanceConstants.STATUS;
const allowedStatus = [
  STATUS.ASSIGNED,
  STATUS.REJECTED,
];

// Get the ID of the authenticated technician
let USER_ID

// Total number of maintenances ASSIGNED to this technician
let MAINTENANCES_COUNT;

// Offset to fetch maintenances PAGE_SIZE by PAGE_SIZE
let OFFSET = 0

function TechDashboard() {
  const [maintenances, setMaintenances] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [dateSort, setDateSort] = useState(null);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [filteredMaintenances, setFilteredMaintenances] = useState([]);

  useEffect(() => {
    let user = authService.getConnectedUser();
    if (user) {
      USER_ID = user.id;
    } else {
      USER_ID = "";
    }
    MAINTENANCES_COUNT = maintenanceService.getCountMaintenances({isDeleted: false, status: { $in: allowedStatus }, userId: USER_ID})
    .then((count) => {MAINTENANCES_COUNT = count.count});
    maintenanceService
      .getMaintenancesWithClients({
        isDeleted: false,
        status: { $in: allowedStatus },
        offset: OFFSET,
        order: DATE_ORDER
      })
      .then((techMaintenances) => {
        setMaintenances(techMaintenances);
      });
  }, [currentpage]);

  const handlePageChange = (newPage) => {
    setCurrentpage(newPage);
    // If the page wanted by the user is > 1, we need an offset to fetch data from DB.
    if (newPage > 1) OFFSET = (newPage - 1) * PAGE_SIZE - 1;
    else OFFSET = 0;
  };

  // Function to sort column
  const sortByColumn = (column, sortOrder) => {
    const sortedList = [...maintenances].sort((a, b) => {
      const valueA = a[column] ? a[column].toString().toLowerCase() : '';
      const valueB = b[column] ? b[column].toString().toLowerCase() : '';

      if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setMaintenances(sortedList);
  };

  // Function to handle order sorting
  const handleSort = (currentSort, setSort) => {
    const newSortOrder = currentSort === 'asc' ? 'desc' : 'asc';
    DATE_ORDER = newSortOrder
    setSort(newSortOrder);
    maintenanceService
      .getMaintenancesWithClients({
        isDeleted: false,
        status: { $in: allowedStatus },
        offset: OFFSET,
        order: DATE_ORDER
      })
      .then((adminMaintenances) => {
        setMaintenances(adminMaintenances);
      });
    //sortByColumn(column, newSortOrder);
  };

  // Function to filter and search in table
  const handleFilter = (event) => {
    const compareValue = event.target.value.toLowerCase();
    setFilterKeyword(compareValue);

    const maintenanceFilteredList = maintenances.filter((maintenance) => {
      return Object.values(maintenance).some((value) => {
        if (value && typeof value === 'string') {
          // Using translated status if possible, otherwise use regular status
          const translatedValue = maintenanceConstants.TRANSLATED_STATUS[value] || value;
          return translatedValue.toLowerCase().includes(compareValue);
        }
        return value ? value.toString().toLowerCase().includes(compareValue) : false;
      });
    });

    setFilteredMaintenances(maintenanceFilteredList);
  };

  // If search input is empty, display full List, else display filtered List
  const displayList = filterKeyword === '' ? maintenances : filteredMaintenances;

  return (
    <div className={css(tablePage.tablePage)}>
      <div className={css(tablePage.header)}>
        <h2 className={css(global.title, tablePage.header)}>TABLEAU DE BORD</h2>
        <div className={css(tablePage.buttons)}>
          <TextInput placeholder="Rechercher" onChange={handleFilter} />
        </div>
      </div>
      {displayList && displayList.length > 0 ? (
        <>
          <div className={css(tablePage.table)}>
            <table className={css(tablePage.table)}>
              <thead>
                <tr>
                  <th className={css(tablePage.cell)}>TYPE</th>
                  <th className={css(tablePage.cell)}>STATUT</th>
                  <th className={css(tablePage.cell)} onClick={() => handleSort(dateSort, setDateSort)}>
                    <div className={css(tablePage.iconTextContainer)}>
                      <span>DATE</span>
                      {dateSort && <span>{dateSort === 'asc' ? <ChevronUp style={iconStyle} /> : <ChevronDown style={iconStyle} />}</span>}
                      {!dateSort && <Selector style={iconStyle} />}
                    </div>
                  </th>
                  <th className={css(tablePage.cell)}>CLIENT</th>
                  <th className={css(tablePage.cell)}>PISCINE</th>
                  <th className={css(tablePage.cell)}>TECHNICIEN(S)</th>
                  <th className={css(tablePage.cell)}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {displayList
                  .map((maintenance) => (
                    <MaintenanceTable key={maintenance.maintenance_id} maintenance={maintenance} />
                  ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentpage={currentpage}
            onChange={handlePageChange}
            total={Math.ceil(MAINTENANCES_COUNT / PAGE_SIZE)}
          />
        </>
      ) : (
        <p>Aucune visite d'entretien en attente d'action de votre part...</p>
      )}
    </div>
  )
}

// Icons style
const iconStyle = {
  width: 14,
  height: 14,
  marginLeft: 4,
};

export default TechDashboard;