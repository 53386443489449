import "styles/App.css";
import Navbar from "./Navbar";
import Login from "./Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Client from "./client/Client";
import ProtectedRoute from "./common/ProtectedRoute";
import NotFound from "./common/NotFound";
import User from "./account/User";
import AccountSettings from "./account/AccountSettings";
import Pool from "./pool/Pool";
import Maintenance from "./maintenance/Maintenance";
import ProductListUpdate from "./product/ProductListUpdate";
import Dashboard from "./dashboard/Dashboard";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="container">
        <Routes>
          <Route index element={<Navigate to="/dashboard" />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/client/*" element={<Client />} />
          <Route path="/pool/*" element={<Pool />} />
          <Route path="/maintenance/*" element={<Maintenance />} />
          <Route path="/user/*" element={<User />} />
          <Route path="/product" element={<ProductListUpdate />} />
          <Route
            exact
            path="/account"
            element={
              <ProtectedRoute>
                <AccountSettings />
              </ProtectedRoute>
            }
          />
          {/* not found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
