import { Button, Group, Loader, TextInput } from "@mantine/core";
import { CirclePlus } from "tabler-icons-react";
import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clientService from "services/clientService";
import ClientCard from "./ClientCard";
import { css } from "aphrodite";
import { cardList, global } from "styles";

function ClientList() {
  const [isLoading, setIsLoading] = useState(true);

  const [clientList, setClientList] = useState([]);
  const [clientListFiltered, setClientListFiltered] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllClients();
  }, []);

  const getAllClients = async () => {
    try {
      const query = { isDeleted: false };
      const result = await clientService.findAllClient({ query });
      const clients = result.data;
      const sortedList =
        clients && clients.length
          ? clients.sort((a, b) => a.lastName.localeCompare(b.lastName))
          : [];
      setClientList(sortedList);
      setClientListFiltered(sortedList);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  function filter(event) {
    const compareValue = event.target.value;
    const clientListFiltered = clientList.filter((client) => {
      const fullname =
        client.firstName.toLowerCase() + client.lastName.toLowerCase();
      return fullname.includes(compareValue.toLowerCase());
    });
    setClientListFiltered(clientListFiltered);
  }

  return (
    <>
      <BackButton />
      <div className={css(cardList.cardList)}>
        <h2 className={css(global.title)}>LISTE DES CLIENTS</h2>
        <Group>
          <Button
            leftIcon={<CirclePlus />}
            onClick={() => navigate(`/client/new`)}
          >
            Ajouter un client
          </Button>
          <TextInput placeholder="Rechercher un client" onChange={filter} />
        </Group>
        <br />
        {isLoading ? (
          <Loader />
        ) : (
          <List clientListFiltered={clientListFiltered} />
        )}
      </div>
    </>
  );
}

function List({ clientListFiltered }) {
  return (
    <div className={css(cardList.list)}>
      {!clientListFiltered.length && <p>Aucun client trouvé</p>}
      {clientListFiltered.map((client) => {
        return <ClientCard key={client.id} client={client} />;
      })}
    </div>
  );
}

export default ClientList;
