import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const clientUrl = "/client/api/v1/client";

const getClient = async (id) => {
  const url = `${baseUrl}${clientUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    console.log({ result });
    throw response;
  }

  return result;
};

const addClient = async (body) => {
  const url = `${baseUrl}${clientUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of clients
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of clients
 */
const findAllClient = async (body) => {
  const options = {
    page: 1,
    paginate: 1000,
  };
  const url = `${baseUrl}${clientUrl}/list`;
  const response = await fetchWrapper().post(url, { options, ...body });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeleteClient = async (id) => {
  const url = `${baseUrl}${clientUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdateClient = async (id, body) => {
  const url = `${baseUrl}${clientUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updateClient = async (id, body) => {
  const url = `${baseUrl}${clientUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const clientService = {
  getClient,
  addClient,
  findAllClient,
  softDeleteClient,
  partialUpdateClient,
  updateClient,
};

export default clientService;
