import { Button } from "@mantine/core";
import { ArrowBackUp } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";

function BackButton({ numberOfPages = 1 }) {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(-numberOfPages)}>
      <Button
        styles={(theme) => ({
          root: {
            "&:hover": {
              background: "none",
            },
          },
        })}
        variant="subtle"
        color="light"
        leftIcon={<ArrowBackUp />}
      >
        Retour
      </Button>
    </div>
  );
}

export default BackButton;
