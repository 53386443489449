import { css } from "aphrodite";
import MaintenanceTable from "components/maintenance/MaintenanceTable";
import maintenanceConstants from "constants/maintenanceConstants";
import { useEffect, useState } from "react";
import authService from "services/authService";
import clientService from "services/clientService";
import maintenanceService from "services/maintenanceService";
import poolPhotoService from "services/poolPhotoService";
import poolService from "services/poolService";
import { global } from "styles";
import tablePage from "styles/table-page";

function TechDashboard() {
  const [maintenances, setMaintenances] = useState([]);

  useEffect(() => {
    const user = authService.getConnectedUser();
    const userId = user.id;
    const clientId = user.client;
    const STATUS = maintenanceConstants.STATUS;
    const allowedStatus = [
      STATUS.TOBEBILLED,
      STATUS.BILLED,
    ];

    async function fetchData() {
      const client = await clientService.getClient(clientId);
      const clientPools = await poolService.findAllPool({
        query: {
          isDeleted: false,
          client: clientId,
        },
      });
      const maintenances = await maintenanceService.findAllMaintenance({
        query: {
          isDeleted: false,
          pool: { $in: clientPools.data.map((pool) => pool.id) },
          status: { $in: allowedStatus },
        },
      });
      setMaintenances(
        maintenances.data.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
    }

    fetchData();
  }, []);

  return (
    <div className={css(tablePage.tablePage)}>
      <h2 className={css(global.title, tablePage.header)}>TABLEAU DE BORD</h2>
      {!maintenances.length && (
        <p>Aucune visite d'entretien en attente d'action de votre part...</p>
      )}
      <table className={css(tablePage.table)}>
        <thead>
          <tr>
            <th className={css(tablePage.cell)}>TYPE</th>
            <th className={css(tablePage.cell)}>STATUT</th>
            <th className={css(tablePage.cell)}>DATE</th>
            <th className={css(tablePage.cell)}>CLIENT</th>
            <th className={css(tablePage.cell)}>PISCINE</th>
            <th className={css(tablePage.cell)}>TECHNICIEN(S)</th>
            <th className={css(tablePage.cell)}>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {maintenances.map((m) => (
            <MaintenanceTable key={m.id} maintenance={m} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TechDashboard;
