import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const productUrl = "/client/api/v1/product";

const getProduct = async (id) => {
  const url = `${baseUrl}${productUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addProduct = async (body) => {
  const url = `${baseUrl}${productUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of products
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of products
 */
const findAllProduct = async (body) => {
  const url = `${baseUrl}${productUrl}/list`;
  const response = await fetchWrapper().post(url, {...body, options: { paginate: 10000 } });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeleteProduct = async (id) => {
  const url = `${baseUrl}${productUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdateProduct = async (id, body) => {
  const url = `${baseUrl}${productUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updateProduct = async (id, body) => {
  const url = `${baseUrl}${productUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const productService = {
  getProduct,
  addProduct,
  findAllProduct,
  softDeleteProduct,
  partialUpdateProduct,
  updateProduct,
};

export default productService;
