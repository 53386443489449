import { StyleSheet } from "aphrodite";

const cardList = StyleSheet.create({
  cardList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em 2rem",
    borderRadius: "10px",
    background: "var(--helio-light-color)",
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.25)",
  },

  header: {
    width: "100%",
    marginBottom: "1rem",
  },

  list: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-around",
    rowGap: "1rem",
    columnGap: "1rem",
    width: "100%",
  },

  search: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: "25px",
  },
});

export default cardList;
