import { Route, Routes } from "react-router-dom";
import NotFound from "components/common/NotFound";
import ProtectedRoute from "components/common/ProtectedRoute";
import UserList from "./UserList";
import UserUpdate from "./UserUpdate";
import UserView from "./UserView";

function User() {
  return (
    <ProtectedRoute routeRoles={["Admin"]}>
      <Routes>
        <Route index element={<UserList />} />
        <Route path=":id" element={<UserView />} />
        <Route path=":id/update" element={<UserUpdate />} />
        <Route path="new" element={<UserUpdate />} />
        {/* not found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ProtectedRoute>
  );
}

export default User;
