import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { css } from "aphrodite";
import { card } from "styles";
import { useEffect, useState } from "react";
import userService from "services/userService";
import maintenanceConstants from "constants/maintenanceConstants";
import clientService from "services/clientService";
import poolService from "services/poolService";

function MaintenanceCard({ maintenance }) {
  const navigate = useNavigate();
  const [technicians, setTechnicians] = useState([]);
  const [pool, setPool] = useState([]);
  const [client, setClient] = useState([]);

  useEffect(() => {
    // get pool
    poolService.getPool(maintenance.pool).then((res) => {
      setPool(res.data);

      // get client
      clientService.getClient(res.data.client).then((res) => {
        setClient(res.data);
      });
    });

    // get technicians
    Promise.all(
      maintenance.technicians.map((id) => userService.getUser(id))
    ).then((res) => {
      setTechnicians(res.map((r) => r.data));
    });
    // eslint-disable-next-line
  }, []);

  const getStatusTooltip = (status) => {
    const STATUS = maintenanceConstants.STATUS;
    switch (status) {
      case STATUS.CREATED:
        return "En attente d'affectation";
      case STATUS.ASSIGNED:
        return "En attente de remplissage";
      case STATUS.SUBMITTED:
        return "En attente de validation";
      case STATUS.REJECTED:
        return "Rejetée : voir commentaire";
      case STATUS.TOBEBILLED:
        return "En attente de facturation";
      case STATUS.BILLED:
        return "Facturée";
      default:
        return status;
    }
  };

  // const getStatusIcon = (status) => {
  //   const STATUS = maintenanceConstants.STATUS;
  //   switch (status) {
  //     case STATUS.CREATED:
  //       return <AlertCircle color="orange" />;
  //     case STATUS.ASSIGNED:
  //       return <AlertCircle color="orange" />;
  //     case STATUS.SUBMITTED:
  //       return <AlertCircle color="orange" />;
  //     case STATUS.REJECTED:
  //       return <CircleX color="red" />;
  //     case STATUS.VALIDATED:
  //       return <CircleCheck color="green" />;
  //     default:
  //       return <AlertCircle color="orange" />;
  //   }
  // };

  return (
    <div className={css(card.panel)}>
      {/* <Tooltip
        className={css(card.toolTip)}
        label={getStatusTooltip(maintenance.status)}
        events={{ hover: true, touch: true, focus: true }}
      >
        <div>{getStatusIcon(maintenance.status)}</div>
      </Tooltip> */}
      {/* <div className={css(card.toolTip)}> */}
      {/* <div>{getStatusIcon(maintenance.status)}</div> */}
      {/* </div> */}
      <div className={css(card.info)}>
        <p className={css(card.name)} style={{ textTransform: "capitalize" }}>
          <b>Type : </b>
          {maintenance.type}
        </p>

        <p className={css(card.name)}>
          <b>Statut : </b>
          {getStatusTooltip(maintenance.status)}
        </p>

        <p className={css(card.name)}>
          <b>Date : </b>
          {new Date(maintenance.date).toLocaleDateString("fr")}
        </p>

        <p className={css(card.name)}>
          <b>Client :</b>
          {/* <a
            className={css(card.link)}
            onClick={() =>
              navigate(`/client/${client.id}`, { state: { client: client } })
            }
          >
            {client.firstName} {client.lastName}
          </a> */}
          <Button
            onClick={() =>
              navigate(`/client/${client.id}`, { state: { client: client } })
            }
          >
            {client.firstName} {client.lastName}
          </Button>
        </p>

        <p className={css(card.name)}>
          <b style={{ flex: "0 0 auto" }}>Piscine : </b>
          <Button
            style={{
              display: "inline-block",
              width: "auto",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onClick={() =>
              navigate(`/pool/${pool.id}`, { state: { pool: pool } })
            }
          >
            {pool.address}
          </Button>
        </p>

        <p className={css(card.name)}>
          <b>Technicien(s) :</b>
          <div className={css(card.name)}>
            {technicians.map((tech) => (
              // <a
              //   className={css(card.link)}
              //   key={tech.id}
              //   onClick={() =>
              //     navigate(`/user/${tech.id}`, { state: { user: tech } })
              //   }
              // >
              //   {tech.username}
              // </a>
              <Button
                key={tech.id}
                onClick={() =>
                  navigate(`/user/${tech.id}`, { state: { user: tech } })
                }
              >
                {tech.username}
              </Button>
            ))}
          </div>
        </p>
        <Button
          onClick={() => navigate(`/maintenance/${maintenance.id}`)}
          color="dark"
        >
          Voir la visite d'entretien
        </Button>
      </div>
    </div>
  );
}

export default MaintenanceCard;
