import { InfoCircle, X } from "tabler-icons-react";

const { showNotification } = require("@mantine/notifications");

function error(message) {
  showNotification({
    message,
    autoClose: 5000,
    color: "alert",
    icon: <X />,
  });
}

function info(message) {
  showNotification({
    message,
    autoClose: 5000,
    color: "green",
    icon: <InfoCircle />,
  });
}

const notif = {
  error,
  info,
};

export default notif;
