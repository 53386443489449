import { StyleSheet } from "aphrodite";

const navbar = StyleSheet.create({
  navbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    height: "fit-content",
    padding: "0 15px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  },

  helio: {
    color: "var(--helio-yellow-color)",
  },

  bulle: {
    color: "var(--helio-green-color)",
  },

  mermaid: {
    width: "100px",
    "@media (max-width: 450px)": {
      display: "none",
    },
  },

  sun: {
    width: "30px",
    "@media (min-width: 451px)": {
      display: "none",
    },
  },

  left: {
    display: "flex",
    alignItems: "center",
    columnGap: "1rem",
  },

  navButtons: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    columnGap: "10px",
  },

  button: {
    width: "30px",
    height: "30px",
    backgroundSize: "cover",
    alignSelf: "center",
  },
});

export default navbar;
