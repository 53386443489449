import { useNavigate } from "react-router-dom";
import maintenanceConstants from "constants/maintenanceConstants";
import { css } from "aphrodite";
import tablePage from "styles/table-page";

function MaintenanceTable({ maintenance }) {
    const navigate = useNavigate();

    const getStatusTooltip = (status) => {
        const { TRANSLATED_STATUS } = maintenanceConstants;
        return TRANSLATED_STATUS[status] || status;
    };

    return (
        <tr key={maintenance.maintenance_id}>
            <td className={css(tablePage.cell, tablePage.noWrapCell)}>{maintenance.type}</td>
            <td className={css(tablePage.cell)}>{getStatusTooltip(maintenance.status)}</td>
            <td className={css(tablePage.cell)}>{new Date(maintenance.date).toLocaleDateString("fr")}</td>
            <td className={css(tablePage.cell, tablePage.link)} onClick={() => navigate(`/client/${maintenance.client_id}`, { state: { client: maintenance.client } })}>
                {maintenance.client_name}
            </td>
            <td className={css(tablePage.cell, tablePage.link)} onClick={() => navigate(`/pool/${maintenance.pool_id}`, { state: { pool: maintenance.pool } })}>
                {maintenance.pool_address}
            </td>
            <td className={css(tablePage.cell, tablePage.link)} onClick={() => navigate(`/user/${maintenance.technician_id}`, { state: { user: maintenance.technicians } })}>
                {maintenance.technician_username}
            </td>
            <td className={css(tablePage.cell, tablePage.link)} onClick={() => navigate(`/maintenance/${maintenance.maintenance_id}`)}>
                Voir la visite d'entretien
            </td>
        </tr>
    );
}

export default MaintenanceTable;