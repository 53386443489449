import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const maintenanceCommentUrl = "/client/api/v1/maintenancecomment";

const getmaintenanceComment = async (id) => {
  const url = `${baseUrl}${maintenanceCommentUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addmaintenanceComment = async (body) => {
  const url = `${baseUrl}${maintenanceCommentUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of maintenanceComments
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of maintenanceComments
 */
const findAllmaintenanceComment = async (body) => {
  const options = {
    page: 1,
    paginate: 1000,
  };
  const url = `${baseUrl}${maintenanceCommentUrl}/list`;
  const response = await fetchWrapper().post(url, { options, ...body });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const getCountMaintenanceComment = async (body) => {
  const options = {
    page: 1,
    paginate: 1000,
  };
  const url = `${baseUrl}${maintenanceCommentUrl}/count`;
  const response = await fetchWrapper().post(url, { options, ...body });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeletemaintenanceComment = async (id) => {
  const url = `${baseUrl}${maintenanceCommentUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdatemaintenanceComment = async (id, body) => {
  const url = `${baseUrl}${maintenanceCommentUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updatemaintenanceComment = async (id, body) => {
  const url = `${baseUrl}${maintenanceCommentUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const maintenanceCommentService = {
  getmaintenanceComment,
  addmaintenanceComment,
  findAllmaintenanceComment,
  getCountMaintenanceComment,
  softDeletemaintenanceComment,
  partialUpdatemaintenanceComment,
  updatemaintenanceComment,
};

export default maintenanceCommentService;
