import { css } from "aphrodite";
import { global, cardList } from "styles";

function OnlineRequired() {
  return (
    <div className={css(cardList.cardList)}>
      <h2 className={css(global.title)}>INTERNET REQUIS</h2>
      <p>vous devez être en ligne pour accéder à cette page</p>
    </div>
  );
}

export default OnlineRequired;
