import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authService from "services/authService";
import { Button, PasswordInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDispatch } from "react-redux";
import { setAuth } from "states/authState";
import { css } from "aphrodite";
import { global, cardList } from "styles";

function Login() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },
  });

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const handleError = (err) => {
    console.log(err);
    if (err.message === "User does not exist") {
      form.setFieldError("username", <p>Identifiant incorrect</p>);
    } else if (err.message === "Incorrect password") {
      form.setFieldError("password", <p>Mot de passe incorrect</p>);
    } else if (err.message.includes("You have exceed the number of limit")) {
      form.setFieldError(
        "password",
        <p>vous avez atteint le nombre limite d'essais. réessayez plus tard</p>
      );
    } else {
      form.setFieldError(
        "username",
        <p>impossible de vous connecter pour le moment</p>
      );
    }
  };

  const login = async (body) => {
    try {
      // set user in localStorage
      const userResult = await authService.login(body);
      const user = userResult.data;
      localStorage.setItem("user", JSON.stringify(user));

      dispatch(setAuth(true));
      navigate("/");
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className={css(cardList.cardList)}>
      <h2 className={css(global.title)}>CONNEXION</h2>

      <form
        className={css(global.form)}
        onSubmit={form.onSubmit((values) => login(values))}
      >
        <TextInput
          required
          label="Identifiant"
          placeholder="Nom d'utilisateur"
          {...form.getInputProps("username")}
        />

        <PasswordInput
          placeholder="Mot de passe"
          label="Mot de passe"
          required
          {...form.getInputProps("password")}
        />

        <Button type="submit">Se connecter</Button>
      </form>
    </div>
  );
}

export default Login;