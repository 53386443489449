const myTheme = {
  // shapes
  defaultRadius: "xl",
  // colors
  colors: {
    primary: [
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
    ],
    secondary: [
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
    ],
    green: [
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
      "#b4dfee",
    ],
    sun: [
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
      "#f39415",
    ],
    alert: [
      "#bd2626",
      "#bd2626",
      "#bd2626",
      "#bd2626",
      "#bd2626",
      "#bd2626",
      "#bd2626",
      "#bd2626",
      "#bd2626",
    ],
    dark: [
      "#585752",
      "#585752",
      "#585752",
      "#585752",
      "#585752",
      "#585752",
      "#585752",
      "#585752",
      "#585752",
    ],
    light: [
      "#f9f8f7",
      "#f9f8f7",
      "#f9f8f7",
      "#f9f8f7",
      "#f9f8f7",
      "#f9f8f7",
      "#f9f8f7",
      "#f9f8f7",
      "#f9f8f7",
    ],
    transparent: [
      "#43ff6400",
      "#43ff6400",
      "#43ff6400",
      "#43ff6400",
      "#43ff6400",
      "#43ff6400",
      "#43ff6400",
      "#43ff6400",
      "#43ff6400",
    ],
  },
  primaryColor: "sun",
  fontFamily: "Poppins, sans-serif",
  // components
  components: {
    Accordion: {
      styles: (theme, params) => ({
        label: {
          color: theme.colors["dark"],
          fontWeight: "900",
        },
        item: {
          color: theme.colors["dark"],
        },
        content: {
          div: {
            margin: "5px",
          },
          dropdown: {
            margin: "0",
          },
        },
      }),
    },
    Tabs: {
      styles: (theme, params) => ({
        tabLabel: {
          color: theme.colors["dark"],
        },
      }),
    },
  },
};

export default myTheme;
