import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const poolPhotoUrl = "/client/api/v1/poolphoto";

const getPoolPhoto = async (id) => {
  const url = `${baseUrl}${poolPhotoUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addPoolPhoto = async (body) => {
  const url = `${baseUrl}${poolPhotoUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of poolPhotos
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of poolPhotos
 */
const findAllPoolPhoto = async (body) => {
  const options = {
    page: 1,
    paginate: 1000,
  };
  const url = `${baseUrl}${poolPhotoUrl}/list`;
  const response = await fetchWrapper().post(url, { options, ...body });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const getCountPoolPhoto = async (body) => {
  const options = {
    page: 1,
    paginate: 1000,
  };
  const url = `${baseUrl}${poolPhotoUrl}/count`;
  const response = await fetchWrapper().post(url, { options, ...body });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeletePoolPhoto = async (id) => {
  const url = `${baseUrl}${poolPhotoUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdatePoolPhoto = async (id, body) => {
  const url = `${baseUrl}${poolPhotoUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updatePoolPhoto = async (id, body) => {
  const url = `${baseUrl}${poolPhotoUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const poolPhotoService = {
  getPoolPhoto,
  addPoolPhoto,
  findAllPoolPhoto,
  getCountPoolPhoto,
  softDeletePoolPhoto,
  partialUpdatePoolPhoto,
  updatePoolPhoto,
};

export default poolPhotoService;
