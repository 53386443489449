import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  Anchor,
  Button,
  Modal,
  Space,
  Tabs,
  Text,
} from "@mantine/core";
import { css } from "aphrodite";
import { view, global, cardList } from "styles";
import poolService from "services/poolService";
import ProtectedRoute from "components/common/ProtectedRoute";
import clientService from "services/clientService";
import maintenanceService from "services/maintenanceService";
import { CirclePlus } from "tabler-icons-react";
import MaintenanceCard from "components/maintenance/MaintenanceCard";
import InfoLine from "components/common/InfoLine";
import PoolPhotoList from "components/poolPhoto/PoolPhotoList";
import poolPhotoService from "services/poolPhotoService";
import authService from "services/authService";
import Unauthorized from "components/common/Unauthorized";

import { Loader, TextInput } from "@mantine/core";
import { ChevronUp, ChevronDown, Selector } from "tabler-icons-react";
import MaintenanceTable from "../maintenance/MaintenanceTable";
import maintenanceConstants from "constants/maintenanceConstants";
import tablePage from "styles/table-page";
import { Pagination } from '@mantine/core';

// Number of table lines per page
const PAGE_SIZE = 10;

// Date order by which maintenances are sorted
let DATE_ORDER = "desc";

const STATUS = maintenanceConstants.STATUS;

// Define allowedStatuses based on userType (1 = admin 2 = technician)
let USER_TYPE
let allowedStatus

// Get the ID of the authenticated technician
let USER_ID

    // Total number of maintenances
let MAINTENANCES_COUNT;

// Offset to fetch maintenances PAGE_SIZE by PAGE_SIZE
let OFFSET = 0

function PoolView() {
  const [opened, setOpened] = useState(false);
  const [pool, setPool] = useState(null);
  const [client, setClient] = useState(null);
  const [maintenances, setMaintenances] = useState([]);
  const [photoCount, setPhotoCount] = useState(0);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [filteredMaintenanceList, setFilteredMaintenanceList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let user = authService.getConnectedUser();
    // If user info are in local storage
    if (user) {
    // Define allowedStatuses based on userType (1 = admin 2 = technician)
    USER_TYPE = user.userType;
      // Get the ID of the authenticated technician
    USER_ID = USER_TYPE === 1 ? null : user.id;
    allowedStatus = USER_TYPE === 1 ? [STATUS.CREATED, STATUS.ASSIGNED, STATUS.SUBMITTED, STATUS.REJECTED, STATUS.TOBEBILLED,STATUS.BILLED] : [STATUS.ASSIGNED, STATUS.REJECTED]
MAINTENANCES_COUNT = maintenanceService.getCountMaintenances({isDeleted: false, status: { $in: allowedStatus }, userId: USER_ID, poolId: id})
.then((count) => {if (count){ MAINTENANCES_COUNT = count.count;} else {MAINTENANCES_COUNT = 0;}});
    } else {
      USER_TYPE = null;
      USER_ID = null;
    }

    // Define allowedStatuses based on userType (1 = admin 2 = technician)
allowedStatus = USER_TYPE === 1 ? [STATUS.CREATED, STATUS.ASSIGNED, STATUS.SUBMITTED, STATUS.REJECTED, STATUS.TOBEBILLED,STATUS.BILLED] : [STATUS.ASSIGNED, STATUS.REJECTED]
    getInfo(id);
    getAllMaintenancesWithClients();
  }, [currentpage]);

  const getAllMaintenancesWithClients = async () => {
    try {
      const maintenancesWithClients = await maintenanceService
        .getMaintenancesWithClients({
          isDeleted: false,
          status: { $in: allowedStatus },
          offset: OFFSET,
          order: DATE_ORDER,
          poolId: id
        })

      setMaintenanceList(maintenancesWithClients);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle pagination
  const handlePageChange = (newPage) => {
    setCurrentpage(newPage);
    // If the page wanted by the user is > 1, we need an offset to fetch data from DB.
    if (newPage > 1) OFFSET = (newPage - 1) * PAGE_SIZE - 1;
    else OFFSET = 0;
  };

  // States for sorted column
  const [dateSort, setDateSort] = useState(null);

  // Function to sort column
  const sortByColumn = (column, sortOrder) => {
    const sortedList = [...maintenanceList].sort((a, b) => {
      const valueA = a[column] ? a[column].toString().toLowerCase() : '';
      const valueB = b[column] ? b[column].toString().toLowerCase() : '';

      if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setMaintenanceList(sortedList);
  };

  // Function to handle order sorting
  const handleSort = (currentSort, setSort) => {
    const newSortOrder = currentSort === 'asc' ? 'desc' : 'asc';
    DATE_ORDER = newSortOrder
    setSort(newSortOrder);
    maintenanceService
      .getMaintenancesWithClients({
        isDeleted: false,
        status: { $in: allowedStatus },
        offset: OFFSET,
        order: DATE_ORDER,
        poolId: id
      })
      .then((adminMaintenances) => {
        setMaintenances(adminMaintenances);
      });
    //sortByColumn(column, newSortOrder);
  };

  // Function to filter and search in table
  const handleFilter = (event) => {
    const compareValue = event.target.value.toLowerCase();
    setFilterKeyword(compareValue);

    const maintenanceFilteredList = maintenanceList.filter((maintenance) =>
      Object.values(maintenance).some((value) => {
        if (value && typeof value === 'string') {
          // Using translated status if possible, otherwise use regular status
          const translatedValue = maintenanceConstants.TRANSLATED_STATUS[value] || value;
          return translatedValue.toLowerCase().includes(compareValue);
        }
        return false;
      })
    );

    setFilteredMaintenanceList(maintenanceFilteredList);
  }

  // If search input is empty, display full List, else display filtered List
  const displayList = filterKeyword === '' ? maintenanceList : filteredMaintenanceList;

  const getInfo = async (id) => {
    try {
      // get pool
      let result = await poolService.getPool(id);
      const pool = result.data;
      if (!pool || pool.isDeleted) {
        navigate("/poolnotfound");
      }

      // if client tries to see another one's pool
      if (
        authService.getConnectedUser().userType === 3 &&
        pool.client.toString() !==
        authService.getConnectedUser().client.toString()
      ) {
        return;
      }

      setPool(pool);

      // get client
      result = await clientService.getClient(parseInt(pool.client));
      setClient(result.data);

      // // get maintenances
      // result = await maintenanceService.findAllMaintenance({
      //   query: { pool: parseInt(id), isDeleted: false },
      // });
      // setMaintenances(
      //   result.data.sort((a, b) => new Date(b.date) - new Date(a.date))
      // );

      // get photo count
      poolPhotoService
        .getCountPoolPhoto({
          where: { pool: pool.id, isDeleted: false },
        })
        .then((res) => {
          if (res) setPhotoCount(res.count);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const deletePool = async (id) => {
    try {
      await poolService.softDeletePool(id);
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  if (
    authService.getConnectedUser().userType === 3 &&
    pool?.client.toString() !== authService.getConnectedUser().client.toString()
  ) {
    return <Unauthorized />;
  }

  return (
    <>
      <BackButton />
      <div className={css(view.view)}>
        <h2 className={css(global.title)}>FICHE PISCINE</h2>
        {/* client info */}
        {client && (
          <div className={css(view.info)}>
            <InfoLine label="Client associé :">
              <Anchor
                variant="text"
                className={css(view.value)}
                component={Link}
                to={`/client/${client.id}`}
              >
                {client.firstName} {client.lastName}
              </Anchor>
            </InfoLine>
            <Space h="md" />
          </div>
        )}

        {/* pool info */}
        {pool ? (
          <Tabs
            className={css(view.info)}
            variant="pills"
            radius={"sm"}
            defaultValue="informations"
          >
            <Tabs.List>
              <Tabs.Tab value="informations">
                <b>Informations</b>
              </Tabs.Tab>
              <Tabs.Tab value="maintenances">
                <b>{`Visites d'entretien (${MAINTENANCES_COUNT})`}</b>
              </Tabs.Tab>
              <Tabs.Tab value="photos">
                <b>Photos ({photoCount})</b>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="informations">
              <PoolInfo
                pool={pool}
                handleModify={() => navigate("update")}
                handleDelete={() => setOpened(true)}
              />
            </Tabs.Panel>

            <Tabs.Panel value="maintenances">
            <div className={css(tablePage.tablePagePoolView)}>
        <div className={css(tablePage.header)}>
          <div className={css(tablePage.buttons)}>
          <Link to={"/maintenance/new"} state={{ poolId: pool.id }}>
            <Button leftIcon={<CirclePlus />} onClick={() => navigate(`/maintenance/new`)}>Ajouter une visite d'entretien</Button>
            </Link>
            <TextInput placeholder="Rechercher" onChange={handleFilter} />
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {displayList && displayList.length > 0 ? (
              <>
                <div className={css(tablePage.table)}>
                  <table className={css(tablePage.table)}>
                    <thead>
                      <tr>
                        <th className={css(tablePage.cell)}>TYPE</th>
                        <th className={css(tablePage.cell)}>STATUT</th>
                        <th className={css(tablePage.cell)} onClick={() => handleSort(dateSort, setDateSort)}>
                          <div className={css(tablePage.iconTextContainer)}>
                            <span>DATE</span>
                            {dateSort && <span>{dateSort === 'asc' ? <ChevronUp style={iconStyle} /> : <ChevronDown style={iconStyle} />}</span>}
                            {!dateSort && <Selector style={iconStyle} />}
                          </div>
                        </th>
                        <th className={css(tablePage.cell)}>CLIENT</th>
                        <th className={css(tablePage.cell)}>PISCINE</th>
                        <th className={css(tablePage.cell)}>TECHNICIEN(S)</th>
                        <th className={css(tablePage.cell)}>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayList
                        .map((maintenance) => (
                          <MaintenanceTable key={maintenance.maintenance_id} maintenance={maintenance} />
                        ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentpage={currentpage}
                  onChange={handlePageChange}
                  total={Math.ceil(MAINTENANCES_COUNT / PAGE_SIZE)}
                />
              </>
            ) : (
              <p>Aucune visite d'entretien trouvée</p>
            )}
          </>
        )}
      </div>
            </Tabs.Panel>

            <Tabs.Panel value="photos">
              <PoolPhotoList
                poolId={parseInt(id)}
                onCountChanged={setPhotoCount}
              />
            </Tabs.Panel>
          </Tabs>
        ) : (
          <p>
            Impossible de récupérer les informations de la piscine pour l'instant...
          </p>
        )}
      </div>

      <DeleteModal
        opened={opened}
        setOpened={setOpened}
        handleClick={() => deletePool(id)}
      />
    </>
  );
}

function PoolInfo({ pool, handleModify, handleDelete }) {
  return (
    <div className={css(view.info)}>
      <ProtectedRoute routeRoles={["Admin", "Tech"]} blockingComponent={<></>}>
        <Button fullWidth onClick={handleModify} color="dark">
          Modifier
        </Button>
      </ProtectedRoute>

      <Accordion defaultValue="caracteristics">
        <Accordion.Item value="caracteristics">
          <Accordion.Control>A/ CARACTÉRISTIQUES DU BASSIN</Accordion.Control>
          <Accordion.Panel>
            <InfoLine label="Adresse:" value={pool.address} />
            <InfoLine
              label="Conditions d'accès au bassin :"
              value={pool.accessConditions}
            />
            <InfoLine
              label="Surface du plan d'eau :"
              value={`${pool.surface} mètres carrés`}
            />
            <InfoLine label="Volume estimé :" value={`${pool.volume} m3`} />
            <InfoLine label="Dimensions :" value={`${pool.dimensions}`} />
            <InfoLine
              label="Extérieur :"
              value={pool.outdoors ? "oui" : "non"}
            />
            <InfoLine
              label="Déshumidification :"
              value={pool.dehumidifying ? "oui" : "non"}
            />
            {pool.dehumidifying && (
              <InfoLine
                label="Marque - modèle :"
                value={pool.dehumidifierBrand}
              />
            )}
            <InfoLine
              label="Type de structure (coque, polystyrène...) :"
              value={pool.structureType}
            />
            <InfoLine
              label="Type de revêtement (liner, membrane...) :"
              value={pool.coatingType}
            />
            <InfoLine
              label="Puits de décompression :"
              value={pool.decompressionWell ? "oui" : "non"}
            />
            {pool.decompressionWell && (
              <InfoLine
                label="Localisation du puits de décompression :"
                value={pool.decompressionWellLocation}
              />
            )}
            <InfoLine
              label="Débordement :"
              value={pool.overflow ? "oui" : "non"}
            />
            <InfoLine
              label="Nombre de skimmers :"
              value={pool.numberOfSkimmers}
            />
            <InfoLine
              label="Nombre de refoulements :"
              value={pool.numberOfPushbacks}
            />
            <InfoLine
              label="Prise balai :"
              value={pool.broomSocket ? "oui" : "non"}
            />
            <InfoLine
              label="Bonde de fond :"
              value={pool.bottomDrain ? "oui" : "non"}
            />
            <InfoLine
              label="Remarques complémentaires :"
              value={pool.otherRemarks}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="filtering">
          <Accordion.Control>B/ FILTRATION</Accordion.Control>
          <Accordion.Panel>
            <InfoLine label="Type de filtration :" value={pool.filteringType} />
            {pool.filteringType === "cartouche" && (
              <InfoLine
                label="Localisation cartouche :"
                value={pool.catridgeLocation}
              />
            )}
            {pool.filteringType === "sable" && (
              <InfoLine label="Si sable, nature du média :">
                {pool.sandMediaType === "autres" ? (
                  <p className={css(view.value)}>{`autre (${pool.otherSandMediaType || "..."
                    })`}</p>
                ) : (
                  <p className={css(view.value)}>
                    {pool.sandMediaType || "..."}
                  </p>
                )}
              </InfoLine>
            )}
            <InfoLine
              label="Filtre marque - modèle :"
              value={pool.filterBrand}
            />
            <InfoLine label="Pompe marque - modèle :" value={pool.pumpBrand} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="equipment">
          <Accordion.Control>C/ ÉQUIPEMENT COMPLÉMENTAIRE</Accordion.Control>
          <Accordion.Panel>
            <InfoLine
              label="Appareil de nettoyage :"
              value={pool.cleaningDevice ? "oui" : "non"}
            />
            {pool.cleaningDevice && (
              <InfoLine
                label="Marque - modèle :"
                value={pool.cleaningDeviceBrand}
              />
            )}
            <InfoLine
              label="Surpresseur :"
              value={pool.booster ? "oui" : "non"}
            />
            {pool.booster && (
              <InfoLine label="Marque - modèle :" value={pool.boosterBrand} />
            )}
            <InfoLine
              label="Nage à contre-courant :"
              value={pool.swimmingAgainstTheCurrent ? "oui" : "non"}
            />
            {pool.swimmingAgainstTheCurrent && (
              <InfoLine
                label="Marque - modèle :"
                value={pool.swimmingAgainstTheCurrentBrand}
              />
            )}

            <Space h="md" />
            <Text size="md" weight={700}>
              1 - Traitement de l'eau
            </Text>
            <InfoLine
              label="Désinfection :"
              value={pool.disinfectionType.join(", ")}
            />
            {pool.disinfectionType.includes("chlore liquide") && (
              <>
                <InfoLine
                  label="Chlore liquide :"
                  value={
                    pool.liquidChlorineAutomated ? "automatique" : "manuel"
                  }
                />
                {pool.liquidChlorineAutomated && (
                  <InfoLine
                    label="Chlore liquide - marque et modèle :"
                    value={pool.liquidChlorineBrand}
                  />
                )}
              </>
            )}
            {pool.disinfectionType.includes("oxygène actif liquide") && (
              <>
                <InfoLine
                  label="Oxygène actif liquide :"
                  value={pool.activeOxygeneAutomated ? "automatique" : "manuel"}
                />
                {pool.activeOxygeneAutomated && (
                  <InfoLine
                    label="Oxygène actif liquide - marque et modèle :"
                    value={pool.activeOxygeneBrand}
                  />
                )}
              </>
            )}
            {pool.disinfectionType.includes("électrolyse sel") && (
              <InfoLine
                label="Électrolyse sel - marque et modèle :"
                value={pool.saltElectrolysisBrand}
              />
            )}

            <InfoLine
              label="Réglage du pH :"
              value={pool.phAutomated ? "automatisé" : "manuel"}
            />
            {pool.phAutomated && (
              <>
                <InfoLine
                  label="Régulation automatisée en :"
                  value={pool.phAutomatedType}
                />
                <InfoLine
                  label="Régulation du pH - marque et modèle :"
                  value={pool.phAutomatedBrand}
                />
              </>
            )}
            <InfoLine
              label="Chambre d'analyse :"
              value={pool.analysisChamber ? "oui" : "non"}
            />

            <Space h="md" />
            <Text size="md" weight={700}>
              2 - CHAUFFAGE DE L'EAU
            </Text>
            <InfoLine
              label="Type de chauffage, marque et modèle :"
              value={pool.waterHeatingBrand}
            />
            <InfoLine label="Localisation :" value={pool.waterHeatingLocation} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="security">
          <Accordion.Control>D/ ÉQUIPEMENT DE SÉCURITÉ</Accordion.Control>
          <Accordion.Panel>
            <Text size="md" weight={700}>
              3 - Couverture
            </Text>
            <InfoLine
              label="Type de couverture :"
              value={pool.coverType.join(", ")}
            />
            <Text size="md" weight={700}>
              4 - Abri
            </Text>
            <InfoLine label="Type d'abri :" value={pool.shelterType} />
            {pool.shelterType && (
              <InfoLine
                label="Mobile :"
                value={pool.shelterMobile ? "oui" : "non"}
              />
            )}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="rules">
          <Accordion.Control>E/ RÈGLEMENTATION</Accordion.Control>
          <Accordion.Panel>
            <Text size="md" weight={700}>
              Rappel de la règlementation en vigueur :
            </Text>
            <Text align="justify">
              Aux termes des articles L128-1 et L128-2 du Code de la
              construction et de habitation, il est notamment prévu que: depuis
              le 1er janvier 2006 toutes les piscines enterrées non closes
              privatives à usage individuel ou collectif doivent être pourvues
              d'un dispositif de sécurité normalisé visant à prévenir le risque
              de noyade.
            </Text>
            <Text align="justify">
              L'obligation d'équiper la piscine d'un dispositif de sécurité
              incombe à son propriétaire exclusivement. De son coté, le
              constructeur ou l'installateur d'une telle piscine doit fournir au
              maitre d'ouvrage une note technique indiquant le dispositif de
              sécurité normalisé retenu
            </Text>
            <Text align="justify">
              L'installation du dispositif de sécurité doit intervenir, au plus
              tard, à la mise en eau de la piscine. A défaut, le propriétaire
              engagerait sa responsabilite, pleine et entière, au regard des
              possibles conséquences liées à l'absence d'un tel dispositif.
            </Text>
            <Space h="md" />
            <InfoLine
              label="Dispositifs retenus par le client :"
              value={pool.securityDevice.join(", ")}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <ProtectedRoute routeRoles={["Admin", "Tech"]} blockingComponent={<></>}>
        <Button fullWidth onClick={handleModify} color="dark">
          Modifier
        </Button>
      </ProtectedRoute>
      <ProtectedRoute routeRoles={["Admin"]} blockingComponent={<></>}>
        <Button fullWidth color="alert" onClick={handleDelete}>
          Supprimer
        </Button>
      </ProtectedRoute>
    </div>
  );
}

function DeleteModal({ opened, setOpened, handleClick }) {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Voulez-vous vraiment supprimer cette piscine ?"
    >
      <div className={css(view.modalButtons)}>
        <Button onClick={handleClick} color="alert">
          Supprimer
        </Button>
        <Button onClick={() => setOpened(false)} color="dark">
          Annuler
        </Button>
      </div>
    </Modal>
  );
}

// Icons style
const iconStyle = {
  width: 14,
  height: 14,
  marginLeft: 4,
};


export default PoolView;
