import { Button, Loader, TextInput } from "@mantine/core";
import { CirclePlus, ChevronUp, ChevronDown, Selector } from "tabler-icons-react";
import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import maintenanceService from "services/maintenanceService";
import MaintenanceTable from "./MaintenanceTable";
import maintenanceConstants from "constants/maintenanceConstants";
import { css } from "aphrodite";
import { global } from "styles";
import tablePage from "styles/table-page";
import { Pagination } from '@mantine/core';

// Number of table lines per page
const PAGE_SIZE = 10;

function MaintenanceList() {
  const [isLoading, setIsLoading] = useState(true);
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [filteredMaintenanceList, setFilteredMaintenanceList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllMaintenancesWithClients();
  }, [currentpage]);

  const getAllMaintenancesWithClients = async () => {
    const STATUS = maintenanceConstants.STATUS;
    const allowedStatus = [
      STATUS.CREATED,
      STATUS.ASSIGNED,
      STATUS.SUBMITTED,
      STATUS.REJECTED,
      STATUS.TOBEBILLED,
      STATUS.BILLED,
    ];
    try {
      const maintenancesWithClients = await maintenanceService
        .getMaintenancesWithClients({
          isDeleted: false,
          status: { $in: allowedStatus },
        })

      setMaintenanceList(maintenancesWithClients);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle pagination
  const handlePageChange = (newPage) => {
    setCurrentpage(newPage);
  };

  // States for sorted column
  const [dateSort, setDateSort] = useState(null);

  // Function to sort column
  const sortByColumn = (column, sortOrder) => {
    const sortedList = [...maintenanceList].sort((a, b) => {
      const valueA = a[column] ? a[column].toString().toLowerCase() : '';
      const valueB = b[column] ? b[column].toString().toLowerCase() : '';

      if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setMaintenanceList(sortedList);
  };

  // Function to handle order sorting
  const handleSort = (column, currentSort, setSort) => {
    const newSortOrder = currentSort === 'asc' ? 'desc' : 'asc';
    setSort(newSortOrder);
    sortByColumn(column, newSortOrder);
  };

  // Function to filter and search in table
  const handleFilter = (event) => {
    const compareValue = event.target.value.toLowerCase();
    setFilterKeyword(compareValue);

    const maintenanceFilteredList = maintenanceList.filter((maintenance) =>
      Object.values(maintenance).some((value) => {
        if (value && typeof value === 'string') {
          // Using translated status if possible, otherwise use regular status
          const translatedValue = maintenanceConstants.TRANSLATED_STATUS[value] || value;
          return translatedValue.toLowerCase().includes(compareValue);
        }
        return false;
      })
    );

    setFilteredMaintenanceList(maintenanceFilteredList);
  }

  // If search input is empty, display full List, else display filtered List
  const displayList = filterKeyword === '' ? maintenanceList : filteredMaintenanceList;

  return (
    <>
      <BackButton />
      <div className={css(tablePage.tablePage)}>
        <div className={css(tablePage.header)}>
          <h2 className={css(global.title)}>LISTE DES VISITES D'ENTRETIEN</h2>
          <div className={css(tablePage.buttons)}>
            <Button leftIcon={<CirclePlus />} onClick={() => navigate(`/maintenance/new`)}>Ajouter une visite d'entretien</Button>
            <TextInput placeholder="Rechercher" onChange={handleFilter} />
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {displayList && displayList.length > 0 ? (
              <>
                <div className={css(tablePage.table)} style={{boxShadow: '4px 4px 30px rgba(0, 0, 0, 0.25) !important'}}>
                  <table className={css(tablePage.table)} style={{boxShadow: '4px 4px 30px rgba(0, 0, 0, 0.25) !important'}}>
                    <thead>
                      <tr>
                        <th className={css(tablePage.cell)}>TYPE</th>
                        <th className={css(tablePage.cell)}>STATUT</th>
                        <th className={css(tablePage.cell)} onClick={() => handleSort('date', dateSort, setDateSort)}>
                          <div className={css(tablePage.iconTextContainer)}>
                            <span>DATE</span>
                            {dateSort && <span>{dateSort === 'asc' ? <ChevronUp style={iconStyle} /> : <ChevronDown style={iconStyle} />}</span>}
                            {!dateSort && <Selector style={iconStyle} />}
                          </div>
                        </th>
                        <th className={css(tablePage.cell)}>CLIENT</th>
                        <th className={css(tablePage.cell)}>PISCINE</th>
                        <th className={css(tablePage.cell)}>TECHNICIEN(S)</th>
                        <th className={css(tablePage.cell)}>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayList
                        .slice((currentpage - 1) * PAGE_SIZE, currentpage * PAGE_SIZE)
                        .map((maintenance) => (
                          <MaintenanceTable key={maintenance.maintenance_id} maintenance={maintenance} />
                        ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentpage={currentpage}
                  onChange={handlePageChange}
                  total={Math.ceil(displayList.length / PAGE_SIZE)}
                />
              </>
            ) : (
              <p>Aucune visite d'entretien trouvée</p>
            )}
          </>
        )}
      </div>
    </>
  );
}

// Icons style
const iconStyle = {
  width: 14,
  height: 14,
  marginLeft: 4,
};

export default MaintenanceList;