import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Group, Loader, Text, TextInput, Tooltip } from "@mantine/core";
import maintenanceCommentService from "services/maintenanceCommentService";
import userService from "services/userService";
import authService from "services/authService";
import { Send } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import notif from "components/common/notification";
import ProtectedRoute from "components/common/ProtectedRoute";

function MaintenanceCommentView({ onCommentCountChange }) {
  const { id } = useParams();
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        // get users
        let res = await userService.findAllUser({
          query: { isDeleted: false },
        });
        setUsers([...res.data, authService.getConnectedUser()]);

        // get comments
        res = await maintenanceCommentService.findAllmaintenanceComment({
          query: { maintenance: parseInt(id) },
        });
        res.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setComments(res.data);
      } catch (err) {
        notif.error(
          "Impossible de récupérer les commentaires pour le moment..."
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  const getCommentUserName = (comment) => {
    const user = users.find((u) => u.id === comment.addedBy);
    return user.username === authService.getConnectedUser().username
      ? "vous"
      : user.username;
  };

  const getFormattedDate = (date) => {
    date = new Date(date);
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  };

  const getFormattedDateTime = (date) => {
    date = new Date(date);
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2)
    );
  };

  const Comments = () => {
    const form = useForm({
      initialValues: {
        newComment: "",
      },
      validate: {
        newComment: (value) => {
          return value === "" ? "Commentaire requis" : null;
        },
      },
    });

    const onFormSubmit = (e) => {
      e.preventDefault();
      if (form.validate().hasErrors) return;

      maintenanceCommentService
        .addmaintenanceComment({
          maintenance: parseInt(id),
          comment: form.values.newComment,
        })
        .then((res) => {
          setComments((current) => [...current, res.data]);
          onCommentCountChange(comments.length + 1);
        })
        .catch(() => notif.error("Impossible d'envoyer de commentaire"));
    };

    return (
      <div style={{ width: "100%" }}>
        {(!comments || !comments.length) && (
          <p>Aucun commentaire pour le moment...</p>
        )}
        {comments.map((com) => (
          <Group position="left" key={com.id}>
            <Tooltip label={`le ${getFormattedDateTime(com.createdAt)}`}>
              <Text weight={800}>{getCommentUserName(com)} :</Text>
            </Tooltip>
            {com.isDeleted ? (
              <Text color="grey">- message supprimé -</Text>
            ) : (
              <>
                <Text>{com.comment}</Text>
                <Tooltip label={`le ${getFormattedDate(com.updatedAt)}`}>
                  <Text color="grey">{com.updatedBy ? "(modifié)" : null}</Text>
                </Tooltip>
              </>
            )}
          </Group>
        ))}

        <br />

        <ProtectedRoute
          routeRoles={["Admin", "Tech"]}
          blockingComponent={<></>}
        >
          <form onSubmit={onFormSubmit}>
            <ProtectedRoute
              requiresOnline
              blockingComponent={
                <TextInput
                  placeholder="Vous devez être en ligne..."
                  {...form.getInputProps("newComment")}
                  disabled
                  rightSection={
                    <Button disabled type="submit">
                      <Send size={20} />
                    </Button>
                  }
                />
              }
            >
              <TextInput
                placeholder="Commentez..."
                {...form.getInputProps("newComment")}
                rightSection={
                  <Button type="submit">
                    <Send size={20} />
                  </Button>
                }
              />
            </ProtectedRoute>
          </form>
        </ProtectedRoute>
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <Group align="center" grow>
          <Loader />
        </Group>
      ) : (
        <Comments />
      )}
    </>
  );
}

export default MaintenanceCommentView;
