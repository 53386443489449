import { StyleSheet } from "aphrodite";

const card = StyleSheet.create({
  panel: {
    width: "100%",
    maxWidth: "340px",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    columnGap: "1rem",
    alignItems: "center",
    padding: "20px",
    background: "var(--helio-green-color)",
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
  },
  info: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  toolTip: {
    position: "absolute",
    top: "-10px",
    right: "10px",
  },
  name: {
    marginBottom: "5px",
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "nowrap",
  },
});

export default card;
