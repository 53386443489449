import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { css } from "aphrodite";
import { card } from "styles";

function ClientCard({ client }) {
  const navigate = useNavigate();

  return (
    <div className={css(card.panel)}>
      <div className={css(card.info)}>
        <p className="name">
          <b>
            {client.firstName} {client.lastName}
          </b>
        </p>
        <p className="address">{client.address}</p>
      </div>
      <div>
        <Button onClick={() => navigate(`/client/${client.id}`)} color="dark">
          Voir
        </Button>
      </div>
    </div>
  );
}

export default ClientCard;
