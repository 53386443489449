import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const userRoleUrl = "/client/api/v1/userrole";

const getUserRole = async (id) => {
  const url = `${baseUrl}${userRoleUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addUserRole = async (body) => {
  const url = `${baseUrl}${userRoleUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addBulkUserRole = async (body) => {
  const url = `${baseUrl}${userRoleUrl}/addBulk`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of userRoles
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of userRoles
 */
const findAllUserRole = async (body) => {
  const url = `${baseUrl}${userRoleUrl}/list`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeleteUserRole = async (id) => {
  const url = `${baseUrl}${userRoleUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const softDeleteManyUserRole = async (body) => {
  const url = `${baseUrl}${userRoleUrl}/softDeleteMany/`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdateUserRole = async (id, body) => {
  const url = `${baseUrl}${userRoleUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updateUserRole = async (id, body) => {
  const url = `${baseUrl}${userRoleUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const deleteUserRole = async (id) => {
  const url = `${baseUrl}${userRoleUrl}/delete/${id}`;
  const response = await fetchWrapper().delete(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const deleteManyUserRole = async (body) => {
  const url = `${baseUrl}${userRoleUrl}/deleteMany`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const userRoleService = {
  getUserRole,
  addUserRole,
  addBulkUserRole,
  findAllUserRole,
  softDeleteUserRole,
  softDeleteManyUserRole,
  partialUpdateUserRole,
  updateUserRole,
  deleteUserRole,
  deleteManyUserRole,
};

export default userRoleService;
