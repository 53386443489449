import { css } from "aphrodite";
import { global, cardList } from "styles";
import BackButton from "./BackButton";

function Unauthorized() {
  return (
    <>
      <BackButton numberOfPages={2} />
      <div className={css(cardList.cardList)}>
        <h2 className={css(global.title)}>NON AUTORISÉ</h2>
        <p>Vous n'avez pas accès à cette page</p>
      </div>
    </>
  );
}

export default Unauthorized;
