const USER_TYPES = {
  Admin: 1,
  Tech: 2,
  Client: 3,
};

// eslint-disable-next-line
export default {
  USER_TYPES,
};
