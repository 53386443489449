import { Button, Loader } from "@mantine/core";
import { CirclePlus } from "tabler-icons-react";
import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import poolService from "services/poolService";
import PoolCard from "./PoolCard";
import { css } from "aphrodite";
import { cardList, global } from "styles";
import ProtectedRoute from "components/common/ProtectedRoute";

function PoolList() {
  const [isLoading, setIsLoading] = useState(true);
  const [poolList, setPoolList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllPools();
  }, []);

  const getAllPools = async () => {
    try {
      const query = { isDeleted: false };
      const result = await poolService.findAllPool({ query });
      const pools = result.data;
      setPoolList(pools);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BackButton />
      <div className={css(cardList.cardList)}>
        <div className={css(cardList.header)}>
          <h2 className={css(global.title)}>LISTE DES PISCINES</h2>
          <ProtectedRoute
            requiresOnline
            blockingComponent={
              <Button leftIcon={<CirclePlus />} disabled>
                Ajouter une piscine
              </Button>
            }
          >
            <Button
              leftIcon={<CirclePlus />}
              onClick={() => navigate(`/pool/new`)}
            >
              Ajouter une piscine
            </Button>
          </ProtectedRoute>
        </div>
        {isLoading ? <Loader /> : <List poolList={poolList} />}
      </div>
    </>
  );
}

function List({ poolList }) {
  return (
    <div className={css(cardList.list)}>
      {!poolList.length && <p>Aucune piscine trouvée</p>}
      {poolList.map((pool) => {
        return <PoolCard key={pool.id} pool={pool} />;
      })}
    </div>
  );
}

export default PoolList;
