import { Button, Group, Loader, TextInput } from "@mantine/core";
import { CirclePlus } from "tabler-icons-react";
import BackButton from "components/common/BackButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userService from "services/userService";
import UserCard from "./UserCard";
import { css } from "aphrodite";
import { cardList, global } from "styles";
import ProtectedRoute from "components/common/ProtectedRoute";

function UserList() {
  const [isLoading, setIsLoading] = useState(true);

  const [userList, setUserList] = useState([]);
  const [userListFiltered, setUserListFiltered] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const body = {
        query: { isDeleted: false },
      };
      const usersResult = await userService.findAllUser(body);
      const users = usersResult.data;
      const sortedList =
        users && users.length
          ? users.sort((a, b) => a.username.localeCompare(b.username))
          : [];
      setUserList(sortedList);
      setUserListFiltered(sortedList);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  function filter(event) {
    const compareValue = event.target.value;
    setUserListFiltered(
      userList.filter((user) => {
        const fullname =
          user.firstName?.toLowerCase() +
          user.lastName?.toLowerCase() +
          user.username.toLowerCase();
        return fullname.includes(compareValue.toLowerCase());
      })
    );
  }

  return (
    <>
      <BackButton />
      <div className={css(cardList.cardList)}>
        <h2 className={css(global.title)}>LISTE DES UTILISATEURS</h2>
        <Group>
          <ProtectedRoute
            requiresOnline
            blockingComponent={
              <Button leftIcon={<CirclePlus />} disabled>
                Ajouter un utilisateur
              </Button>
            }
          >
            <Button
              leftIcon={<CirclePlus />}
              onClick={() => navigate(`/user/new`)}
            >
              Ajouter un utilisateur
            </Button>
          </ProtectedRoute>
          <TextInput
            placeholder="Rechercher un utilisateur"
            onChange={filter}
          />
        </Group>
        <br />
        {isLoading ? <Loader /> : <List userListFiltered={userListFiltered} />}
      </div>
    </>
  );
}

function List({ userListFiltered }) {
  return (
    <div className={css(cardList.list)}>
      {!userListFiltered.length && <p>Aucun utilisateur trouvé</p>}
      {userListFiltered.map((user) => {
        return <UserCard key={user.id} user={user} />;
      })}
    </div>
  );
}

export default UserList;
