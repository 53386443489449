import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const poolUrl = "/client/api/v1/pool";

const getPool = async (id) => {
  const url = `${baseUrl}${poolUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addPool = async (body) => {
  const url = `${baseUrl}${poolUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of pools
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of pools
 */
const findAllPool = async (body) => {
  const options = {
    page: 1,
    paginate: 1000,
  };
  const url = `${baseUrl}${poolUrl}/list`;
  const response = await fetchWrapper().post(url, { options, ...body });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeletePool = async (id) => {
  const url = `${baseUrl}${poolUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdatePool = async (id, body) => {
  const url = `${baseUrl}${poolUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updatePool = async (id, body) => {
  const url = `${baseUrl}${poolUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const poolService = {
  getPool,
  addPool,
  findAllPool,
  softDeletePool,
  partialUpdatePool,
  updatePool,
};

export default poolService;
