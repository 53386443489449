import { useSelector, useDispatch } from "react-redux";
import { setAuth } from "states/authState";
import { Link, useNavigate } from "react-router-dom";
import { ActionIcon, Button, Image, Modal, Tooltip } from "@mantine/core";
import { Logout, Login as LoginIcon, User } from "tabler-icons-react";
import { css } from "aphrodite";
import { navbar, view } from "styles";
import authService from "services/authService";
import { useState } from "react";
import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");
const baseUrl = urlServiceProvider.baseUrl;
const authUrl = "/client/auth";

function Navbar() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);

  const navigate = useNavigate();

  const logout = async () => {
    try {
      // Call backend function defined in the controller
      await fetchWrapper().post(`${baseUrl}${authUrl}/logout`);

      // Execute frontend function to empty the local storage
      localStorage.removeItem("user");
      localStorage.removeItem("roles");
      localStorage.removeItem("userRoles");
      dispatch(setAuth(false));
      setOpened(false);

      // Redirect user to login page
      navigate("/login");
    } catch (error) {
      console.error("Error during disconnection:", error);
      alert("An error has occurred during disconnection.");
    }
  };

  const account = () => {
    navigate("/account");
  };

  return (
    <>
      <div className={css(navbar.navbar)}>
        <Link
          to="/"
          onClick={() => window.location.reload()}
          style={{ textDecoration: "none" }}
        >
          <div className={css(navbar.left)}>
            <Image className={css(navbar.sun)} src="/sun.svg" />
            <h1>
              <span className={css(navbar.helio)}>HELIO</span>
              <span className={css(navbar.bulle)}>BULLE</span>
            </h1>
            <Image className={css(navbar.mermaid)} src="/mermaid.svg" />
          </div>
        </Link>
        {auth.isAuthenticated ? (
          <div className={css(navbar.navButtons)}>
            <Tooltip
              label={`Connecté en tant que ${authService.getConnectedUser().username
                }`}
              events={{ touch: true, focus: true, hover: true }}
            >
              <ActionIcon
                className={css(navbar.button)}
                onClick={account}
                variant="transparent"
                size={30}
              >
                <User color={"white"} size={100} />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              label="Se déconnecter"
              events={{ touch: true, focus: true, hover: true }}
            >
              <ActionIcon
                className={css(navbar.button)}
                onClick={() => setOpened(true)}
                variant="transparent"
                size={35}
              >
                <Logout color={"white"} size={100} />
              </ActionIcon>
            </Tooltip>
          </div>
        ) : (
          <ActionIcon
            className={css(navbar.button)}
            onClick={() => navigate("/login")}
            variant="transparent"
            size={35}
          >
            <LoginIcon color={"white"} size={100} />
          </ActionIcon>
        )}
      </div>
      <LogoutModal
        opened={opened}
        setOpened={setOpened}
        handleClick={() => logout()}
      />
    </>
  );
}

function LogoutModal({ opened, setOpened, handleClick }) {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Voulez-vous vraiment vous déconnecter ?"
    >
      <div className={css(view.modalButtons)}>
        <Button onClick={handleClick} color="alert">
          Se déconnecter
        </Button>
        <Button onClick={() => setOpened(false)} color="dark">
          Annuler
        </Button>
      </div>
    </Modal>
  );
}

export default Navbar;
