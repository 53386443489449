import { StyleSheet } from "aphrodite";

const view = StyleSheet.create({
  view: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em 2rem",
    borderRadius: "10px",
    background: "var(--helio-light-color)",
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.25)",
  },

  info: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1rem",
    width: "100%",
    maxWidth: "500px",
  },

  infoLine: {
    display: "flex",
    flexDirection: "row",
    columnGap: "1rem",
    width: "100%",
    alignItems: "center",
    "@media (max-width: 400px)": {
      flexWrap: "wrap",
    },
  },

  label: {
    width: "100%",
    maxWidth: "fit-content",
  },

  value: {
    display: "flex",
    width: "100%",
    height: "fit-content",
    wordBreak: "break-word",
    alignItems: "center",
    justifyContent: "space-between",
  },

  modalButtons: {
    width: "100%",
    display: "flex",
    columnGap: "1rem",
    justifyContent: "center",
  },
});

export default view;
