const STATUS = {
  CREATED: "CREATED",
  ASSIGNED: "ASSIGNED",
  SUBMITTED: "SUBMITTED",
  REJECTED: "REJECTED",
  TOBEBILLED: "TOBEBILLED",
  BILLED: "BILLED",
};

const TRANSLATED_STATUS = {
  [STATUS.CREATED]: "En attente d'affectation",
  [STATUS.ASSIGNED]: "En attente de remplissage",
  [STATUS.SUBMITTED]: "En attente de validation",
  [STATUS.REJECTED]: "Rejetée : voir commentaire",
  [STATUS.TOBEBILLED]: "En attente de facturation",
  [STATUS.BILLED]: "Facturée",
};

export default {
  STATUS,
  TRANSLATED_STATUS,
};
