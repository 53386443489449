import { useEffect, useState, forwardRef } from "react";
import { Group, Text, Select, ActionIcon } from "@mantine/core";
import clientContractService from "services/clientContractService";
import { CircleMinus } from "tabler-icons-react";

const SelectItem = forwardRef(
  ({ label, value, deleteContract, ...props }, ref) => (
    <Group noWrap>
      <ActionIcon
        onClick={() => {
          deleteContract(value);
        }}
      >
        <CircleMinus />
      </ActionIcon>
      <div ref={ref} {...props}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    </Group>
  )
);

export const ClientContractSelect = ({
  valueProp,
  changeCallback,
  createCallback,
}) => {
  const [contract, setContract] = useState([]);

  useEffect(() => {
    const getAllContracts = async () => {
      try {
        const query = { isDeleted: false };
        const result = await clientContractService.findAllClientContract({
          query,
        });
        const clientContracts = result.data;
        setContract(
          clientContracts && clientContracts.length
            ? clientContracts.map((c) => ({
                value: String(c.id),
                label: c.description,
              }))
            : []
        );
      } catch (err) {
        console.error(err);
      }
    };
    getAllContracts();
  }, []);

  const createContract = async (description) => {
    try {
      let contract = await clientContractService.addClientContract({
        description,
      });
      contract = contract.data;
      setContract((current) => [
        ...current,
        { value: String(contract.id), label: contract.description },
      ]);
      changeCallback(String(contract.id));
    } catch (err) {
      console.error(err);
    }
  };

  const deleteContract = async (id) => {
    try {
      await clientContractService.softDeleteClientContract(id);
      setContract((current) => current.filter((c) => c.value !== id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Select
      label="Contrat client"
      data={contract}
      placeholder="Sélectionnez un contrat"
      nothingFound="Aucun type de contrat existant"
      itemComponent={(props) => (
        <SelectItem {...props} deleteContract={deleteContract} />
      )}
      searchable
      creatable
      clearable
      getCreateLabel={(query) => `Créer "${query}"`}
      onCreate={(query) => createContract(query)}
      filter={(value, item) =>
        item.label.toLowerCase().includes(value.toLowerCase().trim())
      }
      onChange={(value) => changeCallback(value)}
      value={valueProp}
    />
  );
};
