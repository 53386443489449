import { StyleSheet } from "aphrodite";

const account = StyleSheet.create({
  account: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em 2rem",
    borderRadius: "10px",
    background: "var(--helio-light-color)",
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.25)",
  },

  notif: {
    position: "absolute",
    top: "2rem",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
});

export default account;
