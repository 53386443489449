import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const roleUrl = "/client/api/v1/role";

const getRole = async (id) => {
  const url = `${baseUrl}${roleUrl}/${id}`;
  const response = await fetchWrapper().get(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const addRole = async (body) => {
  const url = `${baseUrl}${roleUrl}/create`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

/**
 * Returns the list of roles
 *
 * @param {*} body The body of the request. Can contain 'query', 'options' and 'isCountOnly'
 * @returns {*} response with the list of roles
 */
const findAllRole = async (body) => {
  const url = `${baseUrl}${roleUrl}/list`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result.data;
};

const softDeleteRole = async (id) => {
  const url = `${baseUrl}${roleUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, { isDeleted: true });
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const partialUpdateRole = async (id, body) => {
  const url = `${baseUrl}${roleUrl}/partial-update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const updateRole = async (id, body) => {
  const url = `${baseUrl}${roleUrl}/update/${id}`;
  const response = await fetchWrapper().put(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const roleService = {
  getRole,
  addRole,
  findAllRole,
  softDeleteRole,
  partialUpdateRole,
  updateRole,
};

export default roleService;
