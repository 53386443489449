import { css } from "aphrodite";
import { view } from "styles";

function InfoLine({ label, value, children }) {
  return (
    <div className={css(view.infoLine)}>
      <p className={css(view.label)}>
        <b>{label}</b>
      </p>
      {value && <p className={css(view.value)}>{value}</p>}
      {!value && !children && <p className={css(view.value)}>...</p>}
      {children}
    </div>
  );
}

export default InfoLine;
