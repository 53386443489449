import { Group, Text } from "@mantine/core";
import notif from "components/common/notification";
import { useEffect, useState } from "react";
import productService from "services/productService";

function SuppliedProductList({ values }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // get all products
    productService
      .findAllProduct()
      .then((res) => {
        setProducts(res.data);
      })
      .catch(() => {
        notif.error("Impossible de récupérer la liste des produits");
      });
  }, []);

  const SuppliedProduct = ({ suppliedProduct }) => {
    return (
      <Group>
        <Text>{products.find((x) => x.id === suppliedProduct.id)?.name} :</Text>
        <Text>{suppliedProduct.quantity}</Text>
      </Group>
    );
  };

  return (
    <>
      <Text weight={800}>Produits fournis</Text>
      {(!values || !values.length) && <Text>Aucun produit fourni</Text>}
      {values.map((suppliedProduct) => (
        <SuppliedProduct
          key={suppliedProduct.id}
          suppliedProduct={suppliedProduct}
        />
      ))}
    </>
  );
}

export default SuppliedProductList;
