import dayjs from "dayjs";
import urlServiceProvider from "services/urlServiceProvider";

const { fetchWrapper } = require("helpers/fetchWrapper");

const baseUrl = urlServiceProvider.baseUrl;
const authUrl = "/client/auth";

const login = async (body) => {
  const url = `${baseUrl}${authUrl}/login`;
  const response = await fetchWrapper().post(url, body);
  const result = await response.json();

  if (!response.ok) {
    throw result;
  }

  return result;
};

const logout = async () => {
  const url = `${baseUrl}${authUrl}/logout`;
  const response = await fetchWrapper().post(url);
  const result = await response.json();

  if (!response.ok) {
    throw response;
  }

  return result;
};

const isAuthenticated = () => {
  return (
    getConnectedUser() !== null &&
    dayjs().isBefore(dayjs(getConnectedUser().tokenExpires))
  );
};

const getConnectedUser = () => {
  const user = localStorage.getItem("user");
  return JSON.parse(user);
};

const getUserType = () => {
  return getConnectedUser().userType;
};

const getUserToken = () => {
  return getConnectedUser()?.token;
};

const getUserRoles = () => {
  const userRoles = localStorage.getItem("userRoles");
  if (userRoles) return JSON.parse(userRoles);
  return [];
};

const getRoles = () => {
  const roles = localStorage.getItem("roles");
  if (roles) return JSON.parse(roles);
  return [];
};

const authService = {
  login,
  logout,
  isAuthenticated,
  getConnectedUser,
  getUserType,
  getUserToken,
  getUserRoles,
  getRoles,
};

export default authService;
