import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { css } from "aphrodite";
import { card } from "styles";
// import { useEffect, useState } from "react";
// import clientService from "services/clientService";

function PoolCard({ pool }) {
  const navigate = useNavigate();
  // const [client, setClient] = useState({});

  // useEffect(() => {
  //   clientService.getClient(pool.client).then((res) => setClient(res.data));
  // }, [pool]);

  return (
    <div className={css(card.panel)}>
      <div className={css(card.info)}>
        <p className="address">
          <b>Adresse : {pool.address}</b>
        </p>
        <p className="address">Dimensions : {pool.dimensions || "..."}</p>
        <p className="name">{pool.outdoors ? "Extérieure" : "Intérieure"}</p>
      </div>
      <div>
        <Button onClick={() => navigate(`/pool/${pool.id}`)} color="dark">
          Voir
        </Button>
      </div>
    </div>
  );
}

export default PoolCard;
