import { createSlice } from "@reduxjs/toolkit";
import authService from "services/authService";

const initialState = {
  isAuthenticated: authService.isAuthenticated(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
