import authService from "services/authService";

export { fetchWrapper };

function fetchWrapper() {
  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
  };

  function request(method) {
    return (url, body) => {
      const requestOptions = {
        method,
        headers: authHeader(url),
      };
      if (body) {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
      return fetch(url, requestOptions);
    };
  }

  // helper functions

  function authHeader() {
    // return auth header with jwt if user is logged in and request is to the api url
    const isLoggedIn = authService.isAuthenticated();
    const token = authService.getUserToken();
    if (isLoggedIn && token) {
      return { Authorization: `Bearer ${token}` };
    } else {
      return {};
    }
  }
}
