import NotFound from "components/common/NotFound";
import ProtectedRoute from "components/common/ProtectedRoute";
import { Routes, Route } from "react-router-dom";
import ClientList from "./ClientList";
import ClientUpdate from "./ClientUpdate";
import ClientView from "./ClientView";

function Client() {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute routeRoles={["Admin"]}>
            <ClientList />
          </ProtectedRoute>
        }
      />
      <Route
        path="new"
        element={
          <ProtectedRoute routeRoles={["Admin"]} requiresOnline>
            <ClientUpdate />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id"
        element={
          <ProtectedRoute>
            <ClientView />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id/update"
        element={
          <ProtectedRoute routeRoles={["Admin"]} requiresOnline>
            <ClientUpdate />
          </ProtectedRoute>
        }
      />
      {/* not found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Client;
